
import { ISelectItem } from "winfakt-vue-components/src/classes";
import Vue from "vue";
import { UserInfo } from "@/models/dossier/user-info";
import { DossierService } from "@/services";

export default Vue.extend({
	data(){
		return {
			users: [] as UserInfo[],
		};
	},
	props: {
		value: {
			type: Array as ()=>String[],
			required: true
		},
		label: String,
		fancy: Boolean,
		includeBookYear0: Boolean
	},
	computed: {
		items():ISelectItem<number>[]{
			let result:ISelectItem<number>[] = this.users.map((u) => {
				return {
					text: u.Firstname ? (u.Firstname + " " + u.Lastname) : u.Email,
					value: u.ID
				};
			});
			return result;
		}
	},
	methods: {
		onInput(value:string[]){
			this.$emit("input", value);
		}
	},
	async created(){
		this.users = await DossierService.getUsers();
	}
});
