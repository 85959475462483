
import Vue from "vue";
import { UserConfigService } from "@/services/user-config-service";
import { BrowseStateBase } from "@/utils/browse/browse-state-base";
import { ISelectItem } from "winfakt-vue-components";
import { ViewService } from "@/services/view-service";

export default Vue.extend({
	data() {
		return {
			settingsDropDownOpen: false
		};
	},
	props: {
		state: {
			type: Object as () => BrowseStateBase,
			required: true,
		}
	},
	computed: {
		isEnabled(): boolean {
			return UserConfigService.hasCurrentUserPermission((p) => (p.IsAdmin || p.getPermissionsByBrowseID(this.state.browse.ID).CanMakeViews));
		},
		itemActions(): ISelectItem<string>[] {

			let items: ISelectItem<string>[] = [
				{
					text: this.$ct("settings.edit"),
					value: "editViewSettings",
				}
			];

			/*if (this.state.querySettings.view.hasDefaultSettings()) {
				items.push({
					text: this.$ct("settings.reset"),
					value: "resetViewSettings",
				});
			}*/
			return items;
		},
	},
	methods: {
		editViewSettings() {
			this.$router.push(`/settings/browse/${this.state.querySettings.view.BrowseID}/view/${this.state.querySettings.view.ID}`);
		},
		async resetViewSettings() {
			let confirm = await this.$wf.confirm(this.$ct("settings.reset-confirm"), this.$t("common.yes"), this.$t("common.no"), this.$ct("common.warning"), "info");
			if (!confirm) return;
			let view = await ViewService.getDefaultViewSettings(this.state.querySettings.view.BrowseID, this.state.querySettings.view.getDefaultSettingsIndex());
			view.ID = this.state.querySettings.view.ID;
			view.UserID = this.state.querySettings.view.UserID;
			await ViewService.updateView(view);
			this.state.setView(view);
			this.state.fetch();
		},
		trigger(method: string) {
			(this as any)[method]();
		},
	},
	i18nEx: {
		componentPrefix: "components.browse"
	}
});
