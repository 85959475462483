import { Operator } from "./condition";
import {
	conditionOperatorWithinDaysAgo,
	conditionOperatorWithinMonthsAgo,
	conditionOperatorWithinYearsAgo,
	conditionOperatorOverDaysAgo,
	conditionOperatorOverMonthsAgo,
	conditionOperatorOverYearsAgo,
	conditionOperatorWithinDays,
	conditionOperatorWithinMonths,
	conditionOperatorWithinYears,
	conditionOperatorOverDays,
	conditionOperatorOverMonths,
	conditionOperatorOverYears
} from "./operator-date-functions";

export function conditionOperatorEq(val1:any, val2:string):boolean {
	if (val1 == val2){
		return true;
	}
	if (typeof(val1) == "string") {
		return val1.toLowerCase() == val2.toLowerCase();
	}
	if (typeof(val1) == "boolean") {
		return `${val1}` == val2;
	}
	let {v1,v2} = parseConditionValuesAsNumber(val1, val2);
	return v1 == v2;
}

export function conditionOperatorNeq(val1:any, val2:string):boolean {
	if (val1 == val2) {
		return false;
	}
	if (typeof(val1) == "string") {
		return val1 != val2;
	}
	let {v1,v2} = parseConditionValuesAsNumber(val1, val2);
	return v1 != v2;
}

function parseConditionValuesAsNumber(val1:any, val2:any):{v1:number, v2:number}{
	if (val1 instanceof Date) {
		let v1Date = val1;
		let v2Date = new Date(val2);
		v1Date.setHours(0,0,0,0);
		v2Date.setHours(0,0,0,0);
		return {v1: v1Date.getTime(), v2: v2Date.getTime()};
	}
	let v1 = parseFloat(`${val1}`);
	let v2 = parseFloat(`${val2}`);
	if (Number.isNaN(v1)){
		v1 = 0.0;
	}
	if (Number.isNaN(v2)){
		v2 = 0.0;
	}
	return {v1, v2};
}

export function conditionOperatorGt(val1:any, val2:string):boolean {
	let {v1,v2} = parseConditionValuesAsNumber(val1, val2);
	return v1 > v2;
}

export function conditionOperatorLt(val1:any, val2:string):boolean {
	let {v1,v2} = parseConditionValuesAsNumber(val1, val2);
	return v1 < v2;
}

export function conditionOperatorGtOrEq(val1:any, val2:string):boolean {
	let {v1,v2} = parseConditionValuesAsNumber(val1, val2);
	return v1 >= v2;
}

export function conditionOperatorLtOrEq(val1:any, val2:string):boolean {
	let {v1,v2} = parseConditionValuesAsNumber(val1, val2);
	return v1 <= v2;
}

export function conditionOperatorIsEmpty(val1:any, val2:string):boolean {
	if (val1 == null) {
		return true;
	}
	return `${val1}` == "";
}

export function conditionOperatorIsNotEmpty(val1:any, val2:string):boolean {
	if (val1 == null) {
		return false;
	}
	return `${val1}` != "";
}

export function conditionOperatorStartsWith(val1:any, val2:any):boolean{
	if (typeof(val1) == "string"){
		return val1.startsWith(val2);
	}
	return false;
}

export function conditionOperatorEndsWith(val1:any, val2:any):boolean{
	if (typeof(val1) == "string"){
		return val1.endsWith(val2);
	}
	return false;
}

export function conditionOperatorContains(val1:any, val2:any):boolean{
	if (typeof(val1) == "string"){
		return val1.indexOf(val2) != -1;
	}
	return false;
}

export function conditionOperatorDoesNotStartWith(val1:any, val2:any):boolean {
	return !conditionOperatorStartsWith(val1, val2);
}

export function conditionOperatorDoesNotEndWith(val1:any, val2:any):boolean {
	return !conditionOperatorEndsWith(val1, val2);
}

export function conditionOperatorDoesNotContain(val1:any, val2:any):boolean {
	return !conditionOperatorContains(val1, val2);
}

// eslint-disable-next-line max-lines-per-function
export function getFunctionByOperator(operator:Operator):(val1:any, val2:string)=>boolean {
	if (operator == Operator.EQ) {
		return conditionOperatorEq;
	} else if (operator == Operator.NEQ) {
		return conditionOperatorNeq;
	} else if (operator == Operator.GT) {
		return conditionOperatorGt;
	} else if (operator == Operator.LT) {
		return conditionOperatorLt;
	} else if (operator == Operator.GT_OR_EQ) {
		return conditionOperatorGtOrEq;
	} else if (operator == Operator.LT_OR_EQ) {
		return conditionOperatorLtOrEq;
	} else if (operator == Operator.IS_EMPTY) {
		return conditionOperatorIsEmpty;
	} else if (operator == Operator.IS_NOT_EMPTY) {
		return conditionOperatorIsNotEmpty;
	} else if (operator == Operator.WITHIN_DAYS_AGO){
		return conditionOperatorWithinDaysAgo;
	} else if (operator == Operator.WITHIN_MONTHS_AGO){
		return conditionOperatorWithinMonthsAgo;
	} else if (operator == Operator.WITHIN_YEARS_AGO){
		return conditionOperatorWithinYearsAgo;
	} else if (operator == Operator.OVER_DAYS_AGO){
		return conditionOperatorOverDaysAgo;
	} else if (operator == Operator.OVER_MONTHS_AGO){
		return conditionOperatorOverMonthsAgo;
	} else if (operator == Operator.OVER_YEARS_AGO){
		return conditionOperatorOverYearsAgo;
	} else if (operator == Operator.WITHIN_DAYS){
		return conditionOperatorWithinDays;
	} else if (operator == Operator.WITHIN_MONTHS){
		return conditionOperatorWithinMonths;
	} else if (operator == Operator.WITHIN_YEARS){
		return conditionOperatorWithinYears;
	} else if (operator == Operator.OVER_DAYS){
		return conditionOperatorOverDays;
	} else if (operator == Operator.OVER_MONTHS){
		return conditionOperatorOverMonths;
	} else if (operator == Operator.OVER_YEARS){
		return conditionOperatorOverYears;
	} else if (operator == Operator.StartsWith) {
		return conditionOperatorStartsWith;
	}else if(operator == Operator.EndsWith) {
		return conditionOperatorEndsWith;
	} else if (operator == Operator.Contains) {
		return conditionOperatorContains;
	} else if (operator == Operator.DoesNotStartWith) {
		return conditionOperatorDoesNotStartWith;
	} else if (operator == Operator.DoesNotEndWith) {
		return conditionOperatorDoesNotEndWith;
	} else if (operator == Operator.DoesNotContain) {
		return conditionOperatorDoesNotContain;
	}
	return conditionOperatorIsNotEmpty;
}


