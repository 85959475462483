export class BankCtepSaleResult {
	BankID:number = 0;
	BookYear:number = 0;
	AcquirerID:string = ""
	AcquirerToken:string = ""
	Action:string = ""
	AdditionalAmount:number | null = null
	AuthorizationCode:string = "";
	AuthorizedAmount:number = 0.0;
	CardBrandIdentifier:string = ""
	CardBrandName:string = ""
	ClientTicket:string = "";
	ClippedPan:string = ""
	IsAsynchronousChip:boolean = false;
	IsBarCode:boolean = false;
	IsContactLess:boolean = false;
	IsIccEmv:boolean = false;
	IsIccNonEmv:boolean = false;
	IsMagneticStripe:boolean = false;
	IsManualEntry:boolean = false;
	IsSynchronousChip:boolean = false;
	Language:string = ""
	MerchantText:string = ""
	MerchantTicket:string = ""
	PartialApproval:boolean | null = null;
	SignatureRequired:boolean = false;
	TerminalIdentifier:string = ""
	TimeStamp:null | Date = null;

	public constructor(data?:any){
		if (!data) return;
		this.BankID = data.BankID;
		this.BookYear = data.BookYear;
		this.AcquirerID = data.AcquirerID;
		this.AcquirerToken = data.AcquirerToken;
		this.Action = data.Action;
		this.AdditionalAmount = data.AdditionalAmount;
		this.AuthorizationCode = data.AuthorizationCode;
		this.AuthorizedAmount = data.AuthorizedAmount;
		this.CardBrandIdentifier = data.CardBrandIdentifier;
		this.CardBrandName = data.CardBrandName;
		this.ClientTicket = data.ClientTicket;
		this.ClippedPan = data.ClippedPan;
		this.IsAsynchronousChip = data.IsAsynchronousChip;
		this.IsBarCode = data.IsBarCode;
		this.IsContactLess = data.IsContactLess;
		this.IsIccEmv = data.IsIccEmv;
		this.IsIccNonEmv = data.IsIccNonEmv;
		this.IsMagneticStripe = data.IsMagneticStripe;
		this.IsManualEntry = data.IsManualEntry;
		this.IsSynchronousChip = data.IsSynchronousChip;
		this.Language = data.Language;
		this.MerchantText = data.MerchantText;
		this.MerchantTicket = data.MerchantTicket;
		this.PartialApproval = data.PartialApproval;
		this.SignatureRequired = data.SignatureRequired;
		this.TerminalIdentifier = data.TerminalIdentifier;
		if (data.TimeStamp) {
			this.TimeStamp = data.TimeStamp;
		}
	}

	public getJSON(){
		let timeStamp:any = this.TimeStamp;
		if (this.TimeStamp) {
			timeStamp = this.TimeStamp?.toJSON();
		}
		return {
			BankID: this.BankID,
			BookYear: this.BookYear,
			AcquirerID: this.AcquirerID,
			AcquirerToken: this.AcquirerToken,
			Action: this.Action,
			AdditionalAmmount: this.AdditionalAmount,
			AuthorizationCode: this.AuthorizationCode,
			AuthorizedAmount: this.AuthorizedAmount,
			CardBrandIdentifier: this.CardBrandIdentifier,
			CardBrandName: this.CardBrandName,
			ClientTicket: this.ClientTicket,
			ClippedPan: this.ClippedPan,
			IsAsynchronousChip: this.IsAsynchronousChip,
			IsBarCode: this.IsBarCode,
			IsContactLess: this.IsContactLess,
			IsIccEmv: this.IsIccEmv,
			IsIccNonEmv: this.IsIccNonEmv,
			IsMagneticStripe: this.IsMagneticStripe,
			IsManualEntry: this.IsManualEntry,
			IsSynchronousChip: this.IsSynchronousChip,
			Language: this.Language,
			MerchantText: this.MerchantText,
			MerchantTicket: this.MerchantTicket,
			PartialApproval: this.PartialApproval,
			SignatureRequired: this.SignatureRequired,
			TerminalIdentifier: this.TerminalIdentifier,
			TimeStamp: timeStamp,
		};
	}
}