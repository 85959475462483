
import { Browse } from "@/models/browse";
import { BrowseFavouriteColumns } from "@/models/browse-favourite-columns";
import { BrowseService } from "@/services";
import { BrowseFavouriteColumn } from "@/utils/browse/browse-favourite-column";
import { IProperty, getValueByPath } from "@/utils/models/property";
import { filterFieldPathToBrowseFieldPath } from "@/utils/models/utils";
import { printBoolean } from "@/utils/pretty-print";
import Vue from "vue";

export default Vue.extend({
	props: {
		active: Boolean,
		path: String,
		name: String,
		fullName: String,
		canFavourite: Boolean,
		showFavourites: Boolean,
		favourites: {
			type: Object as ()=>BrowseFavouriteColumns,
			required: false
		},
		browse: Object as ()=>Browse,
		filterText: String,
		filterableOnly: Boolean,
		previewValue: {
			type: Object as any
		},
		field: {
			type: Object as ()=> IProperty
		}
	},
	computed: {
		browsePath():string{
			if (!this.filterableOnly){
				return this.path;
			}
			return filterFieldPathToBrowseFieldPath(this.path);
		},
		shouldShow():boolean{
			if (this.active) return true;
			if (this.favouriteIndex == -1 && this.showFavourites){
				return false;
			}
			let fullName = (this.fullName + " " + this.name).toLowerCase();
			if (fullName.includes(this.filterText)) {
				return true;
			}
			return false;
		},
		favouriteIndex():number{
			if (!this.favourites) return -1;
			return this.favourites.getIndexOfPath(this.browsePath);
		},
		icon():string[]{
			if (this.favouriteIndex == -1){
				return ["far", "star"];
			}
			return ["fas", "star"];
		},
		previewValueText():any{
			if (!this.previewValue) return "";
			let value = getValueByPath(this.previewValue, this.browsePath);
			let pathParts = this.browsePath.split(".");
			pathParts.pop();
			let path = pathParts.join(".");
			let lowestItem = getValueByPath(this.previewValue, path);
			if (!lowestItem){
				lowestItem = this.previewValue;
			}
			if (this.field.print){
				return this.field.print(value, this.previewValue, lowestItem);
			}
			let v = value;
			if (typeof(v) == "boolean"){
				return printBoolean(v);
			}
			return v;
		}
	},
	methods: {
		onFieldClick(){
			this.$emit("click");
		},
		async toggleFavourite(){
			if (!this.browse) return;
			if (!this.favourites) return;
			if (this.favouriteIndex == -1){
				let field = new BrowseFavouriteColumn();
				field.Path = this.path;
				this.favourites.Columns.push(field);
			}else{
				this.favourites.Columns.splice(this.favouriteIndex, 1);
			}
			let favourites = BrowseService.getFavouriteColumnsByBrowseId(this.browse.ID);
			favourites.Columns = this.favourites.Columns;
			await BrowseService.updateBrowseFavouriteColumns(favourites);
		}
	},
});
