
export class Calendar {
	ID:number = 0;
	Name:string = "";
	OwnerID:number = 0;
	Color:string = "#3788d8";
	MinTime:string = "";
	MaxTime:string = "";

	constructor(data?:any) {
		if (!data) {
			return;
		}
		this.ID = data.ID;
		this.Name = data.Name;
		this.OwnerID = data.OwnerID;
		this.Color = data.Color;
		this.MinTime = data.MinTime || "00:00:00";
		this.MaxTime = data.MaxTime || "23:59:59";
		if (this.Color == "") {
			this.Color = "#3788d8";
		}
	}

	getJSON() {
		return {
			ID: this.ID,
			Name: this.Name,
			OwnerID: this.OwnerID,
			Color: this.Color,
			MinTime: this.MinTime,
			MaxTime: this.MaxTime
		};
	}


	static isTimeSmaller(time1:string, time2:string) {
		let [h1,m1,s1] = time1.split(":");
		let [h2,m2,s2] = time2.split(":");
		if (h1 < h2) {
			return true;
		}
		if (h1 > h2) {
			return false;
		}
		if (m1 < m2) {
			return true;
		}
		if (m1 > m2) {
			return false;
		}
		if (s1 < s2) {
			return true;
		}
		return false;
	}
}