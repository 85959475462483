import { getFileUrl } from "@/utils/url";
import { ILayoutImage } from "../layout-editor";

export class DocumentLayoutLogoBase implements ILayoutImage {
	public ImageToken = "";
	public X = 0;
	public Y = 0;
	public Width = 50;
	public Height = 50;
	constructor(data?: any) {
		if (!data) {
			return;
		}
		this.ImageToken = data.ImageToken;
		this.X = data.X;
		this.Y = data.Y;
		this.Width = data.Width;
		this.Height = data.Height;
	}
	public getJSON() {
		return {
			ImageToken: this.ImageToken,
			X: this.X,
			Y: this.Y,
			Width: this.Width,
			Height: this.Height,
		};
	}

	public getImageUrl():string {
		return getFileUrl(this.ImageToken);
	}

	public getExtraSettingsComponent():string{
		return "";
	}
}


