/* eslint-disable max-lines-per-function */
import { DossierConfigService } from "@/services";
import { field, nullable, onClick, print, translatedName } from "@/utils/models/decorator-field";
import { model } from "@/utils/models/decorator-model";
import { parseDateFromData, convertDateToNullOrJSON, IExtraFields } from "@/utils/models/extra-fields";
import { printBoolean, printLocalDate } from "@/utils/pretty-print";
import { ExtraFieldNamesConfig } from "../dossier-configs/extra-field-names-config";
import { extraFieldEditClickAction } from "@/utils/models/click-action-handlers";

@model("ContactExtraFields")
export default class ContactExtraFields implements IExtraFields{
	public contactGroupId:number = 1;

	@field("number")
	public ID:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Num1)
	public Num1:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Num2)
	public Num2:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Num3)
	public Num3:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Num4)
	public Num4:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Num5)
	public Num5:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Num6)
	public Num6:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Num7)
	public Num7:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Num8)
	public Num8:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Num9)
	public Num9:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Num10)
	public Num10:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Num11)
	public Num11:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Num12)
	public Num12:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Num13)
	public Num13:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Num14)
	public Num14:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Num15)
	public Num15:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Num16)
	public Num16:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Num17)
	public Num17:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Num18)
	public Num18:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Num19)
	public Num19:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Num20)
	public Num20:number = 0;

	//-----------------------------------------------------------------------------------

	@field("string")
	@onClick(extraFieldEditClickAction)
	@print((v:string, highestParent:any, fields:any)=>fields.getExtraFieldNamesConfig().Text1Config.printValue(v))
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Text1)
	public Text1:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@print((v:string, highestParent:any, fields:any)=>fields.getExtraFieldNamesConfig().Text2Config.printValue(v))
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Text2)
	public Text2:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@print((v:string, highestParent:any, fields:any)=>fields.getExtraFieldNamesConfig().Text3Config.printValue(v))
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Text3)
	public Text3:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@print((v:string, highestParent:any, fields:any)=>fields.getExtraFieldNamesConfig().Text4Config.printValue(v))
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Text4)
	public Text4:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@print((v:string, highestParent:any, fields:any)=>fields.getExtraFieldNamesConfig().Text5Config.printValue(v))
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Text5)
	public Text5:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@print((v:string, highestParent:any, fields:any)=>fields.getExtraFieldNamesConfig().Text6Config.printValue(v))
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Text6)
	public Text6:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@print((v:string, highestParent:any, fields:any)=>fields.getExtraFieldNamesConfig().Text7Config.printValue(v))
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Text7)
	public Text7:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@print((v:string, highestParent:any, fields:any)=>fields.getExtraFieldNamesConfig().Text8Config.printValue(v))
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Text8)
	public Text8:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@print((v:string, highestParent:any, fields:any)=>fields.getExtraFieldNamesConfig().Text9Config.printValue(v))
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Text9)
	public Text9:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@print((v:string, highestParent:any, fields:any)=>fields.getExtraFieldNamesConfig().Text10Config.printValue(v))
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Text10)
	public Text10:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@print((v:string, highestParent:any, fields:any)=>fields.getExtraFieldNamesConfig().Text11Config.printValue(v))
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Text11)
	public Text11:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@print((v:string, highestParent:any, fields:any)=>fields.getExtraFieldNamesConfig().Text12Config.printValue(v))
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Text12)
	public Text12:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@print((v:string, highestParent:any, fields:any)=>fields.getExtraFieldNamesConfig().Text13Config.printValue(v))
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Text13)
	public Text13:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@print((v:string, highestParent:any, fields:any)=>fields.getExtraFieldNamesConfig().Text14Config.printValue(v))
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Text14)
	public Text14:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@print((v:string, highestParent:any, fields:any)=>fields.getExtraFieldNamesConfig().Text15Config.printValue(v))
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Text15)
	public Text15:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@print((v:string, highestParent:any, fields:any)=>fields.getExtraFieldNamesConfig().Text16Config.printValue(v))
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Text16)
	public Text16:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@print((v:string, highestParent:any, fields:any)=>fields.getExtraFieldNamesConfig().Text17Config.printValue(v))
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Text17)
	public Text17:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@print((v:string, highestParent:any, fields:any)=>fields.getExtraFieldNamesConfig().Text18Config.printValue(v))
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Text18)
	public Text18:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@print((v:string, highestParent:any, fields:any)=>fields.getExtraFieldNamesConfig().Text19Config.printValue(v))
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Text19)
	public Text19:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@print((v:string, highestParent:any, fields:any)=>fields.getExtraFieldNamesConfig().Text20Config.printValue(v))
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Text20)
	public Text20:string = "";

	//------------------------------------------------------------------------------------

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@print(printBoolean)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Bool1)
	public Bool1:boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@print(printBoolean)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Bool2)
	public Bool2:boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@print(printBoolean)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Bool3)
	public Bool3:boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@print(printBoolean)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Bool4)
	public Bool4:boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@print(printBoolean)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Bool5)
	public Bool5:boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@print(printBoolean)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Bool6)
	public Bool6:boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@print(printBoolean)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Bool7)
	public Bool7:boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@print(printBoolean)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Bool8)
	public Bool8:boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@print(printBoolean)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Bool9)
	public Bool9:boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@print(printBoolean)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Bool10)
	public Bool10:boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@print(printBoolean)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Bool11)
	public Bool11:boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@print(printBoolean)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Bool12)
	public Bool12:boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@print(printBoolean)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Bool13)
	public Bool13:boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@print(printBoolean)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Bool14)
	public Bool14:boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@print(printBoolean)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Bool15)
	public Bool15:boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@print(printBoolean)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Bool16)
	public Bool16:boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@print(printBoolean)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Bool17)
	public Bool17:boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@print(printBoolean)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Bool18)
	public Bool18:boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@print(printBoolean)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Bool19)
	public Bool19:boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@print(printBoolean)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Bool20)
	public Bool20:boolean = false;

	//------------------------------------------------------------------------------------

	@field("date")
	@onClick(extraFieldEditClickAction)
	@print(printLocalDate)
	@nullable()
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Time1)
	public Time1:Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@print(printLocalDate)
	@nullable()
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Time2)
	public Time2:Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@print(printLocalDate)
	@nullable()
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Time3)
	public Time3:Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@print(printLocalDate)
	@nullable()
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Time4)
	public Time4:Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@print(printLocalDate)
	@nullable()
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Time5)
	public Time5:Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@print(printLocalDate)
	@nullable()
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Time6)
	public Time6:Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@print(printLocalDate)
	@nullable()
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Time7)
	public Time7:Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@print(printLocalDate)
	@nullable()
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Time8)
	public Time8:Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@print(printLocalDate)
	@nullable()
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Time9)
	public Time9:Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@print(printLocalDate)
	@nullable()
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Time10)
	public Time10:Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@print(printLocalDate)
	@nullable()
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Time11)
	public Time11:Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@print(printLocalDate)
	@nullable()
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Time12)
	public Time12:Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@print(printLocalDate)
	@nullable()
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Time13)
	public Time13:Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@print(printLocalDate)
	@nullable()
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Time14)
	public Time14:Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@print(printLocalDate)
	@nullable()
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Time15)
	public Time15:Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@print(printLocalDate)
	@nullable()
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Time16)
	public Time16:Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@print(printLocalDate)
	@nullable()
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Time17)
	public Time17:Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@print(printLocalDate)
	@nullable()
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Time18)
	public Time18:Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@print(printLocalDate)
	@nullable()
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Time19)
	public Time19:Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@print(printLocalDate)
	@nullable()
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames.Time20)
	public Time20:Date | null = null;

	//------------------------------------------------------------------------------------

	public constructor(data: any = null) {
		if (!data) {
			return;
		}
		this.ID = data.ID;

		this.Num1 = data.Num1;
		this.Num2 = data.Num2;
		this.Num3 = data.Num3;
		this.Num4 = data.Num4;
		this.Num5 = data.Num5;
		this.Num6 = data.Num6;
		this.Num7 = data.Num7;
		this.Num8 = data.Num8;
		this.Num9 = data.Num9;
		this.Num10 = data.Num10;
		this.Num11 = data.Num11;
		this.Num12 = data.Num12;
		this.Num13 = data.Num13;
		this.Num14 = data.Num14;
		this.Num15 = data.Num15;
		this.Num16 = data.Num16;
		this.Num17 = data.Num17;
		this.Num18 = data.Num18;
		this.Num19 = data.Num19;
		this.Num20 = data.Num20;

		this.Text1 = data.Text1;
		this.Text2 = data.Text2;
		this.Text3 = data.Text3;
		this.Text4 = data.Text4;
		this.Text5 = data.Text5;
		this.Text6 = data.Text6;
		this.Text7 = data.Text7;
		this.Text8 = data.Text8;
		this.Text9 = data.Text9;
		this.Text10 = data.Text10;
		this.Text11 = data.Text11;
		this.Text12 = data.Text12;
		this.Text13 = data.Text13;
		this.Text14 = data.Text14;
		this.Text15 = data.Text15;
		this.Text16 = data.Text16;
		this.Text17 = data.Text17;
		this.Text18 = data.Text18;
		this.Text19 = data.Text19;
		this.Text20 = data.Text20;

		this.Bool1 = data.Bool1;
		this.Bool2 = data.Bool2;
		this.Bool3 = data.Bool3;
		this.Bool4 = data.Bool4;
		this.Bool5 = data.Bool5;
		this.Bool6 = data.Bool6;
		this.Bool7 = data.Bool7;
		this.Bool8 = data.Bool8;
		this.Bool9 = data.Bool9;
		this.Bool10 = data.Bool10;
		this.Bool11 = data.Bool11;
		this.Bool12 = data.Bool12;
		this.Bool13 = data.Bool13;
		this.Bool14 = data.Bool14;
		this.Bool15 = data.Bool15;
		this.Bool16 = data.Bool16;
		this.Bool17 = data.Bool17;
		this.Bool18 = data.Bool18;
		this.Bool19 = data.Bool19;
		this.Bool20 = data.Bool20;

		this.Time1 = parseDateFromData(data.Time1);
		this.Time2 = parseDateFromData(data.Time2);
		this.Time3 = parseDateFromData(data.Time3);
		this.Time4 = parseDateFromData(data.Time4);
		this.Time5 = parseDateFromData(data.Time5);
		this.Time6 = parseDateFromData(data.Time6);
		this.Time7 = parseDateFromData(data.Time7);
		this.Time8 = parseDateFromData(data.Time8);
		this.Time9 = parseDateFromData(data.Time9);
		this.Time10 = parseDateFromData(data.Time10);
		this.Time11 = parseDateFromData(data.Time11);
		this.Time12 = parseDateFromData(data.Time12);
		this.Time13 = parseDateFromData(data.Time13);
		this.Time14 = parseDateFromData(data.Time14);
		this.Time15 = parseDateFromData(data.Time15);
		this.Time16 = parseDateFromData(data.Time16);
		this.Time17 = parseDateFromData(data.Time17);
		this.Time18 = parseDateFromData(data.Time18);
		this.Time19 = parseDateFromData(data.Time19);
		this.Time20 = parseDateFromData(data.Time20);
	}

	public getJSON() {
		return {
			ID: this.ID,
			Num1: this.Num1,
			Num2: this.Num2,
			Num3: this.Num3,
			Num4: this.Num4,
			Num5: this.Num5,
			Num6: this.Num6,
			Num7: this.Num7,
			Num8: this.Num8,
			Num9: this.Num9,
			Num10: this.Num10,
			Num11: this.Num11,
			Num12: this.Num12,
			Num13: this.Num13,
			Num14: this.Num14,
			Num15: this.Num15,
			Num16: this.Num16,
			Num17: this.Num17,
			Num18: this.Num18,
			Num19: this.Num19,
			Num20: this.Num20,

			Text1: this.Text1,
			Text2: this.Text2,
			Text3: this.Text3,
			Text4: this.Text4,
			Text5: this.Text5,
			Text6: this.Text6,
			Text7: this.Text7,
			Text8: this.Text8,
			Text9: this.Text9,
			Text10: this.Text10,
			Text11: this.Text11,
			Text12: this.Text12,
			Text13: this.Text13,
			Text14: this.Text14,
			Text15: this.Text15,
			Text16: this.Text16,
			Text17: this.Text17,
			Text18: this.Text18,
			Text19: this.Text19,
			Text20: this.Text20,

			Bool1: this.Bool1,
			Bool2: this.Bool2,
			Bool3: this.Bool3,
			Bool4: this.Bool4,
			Bool5: this.Bool5,
			Bool6: this.Bool6,
			Bool7: this.Bool7,
			Bool8: this.Bool8,
			Bool9: this.Bool9,
			Bool10: this.Bool10,
			Bool11: this.Bool11,
			Bool12: this.Bool12,
			Bool13: this.Bool13,
			Bool14: this.Bool14,
			Bool15: this.Bool15,
			Bool16: this.Bool16,
			Bool17: this.Bool17,
			Bool18: this.Bool18,
			Bool19: this.Bool19,
			Bool20: this.Bool20,

			Time1: convertDateToNullOrJSON(this.Time1),
			Time2: convertDateToNullOrJSON(this.Time2),
			Time3: convertDateToNullOrJSON(this.Time3),
			Time4: convertDateToNullOrJSON(this.Time4),
			Time5: convertDateToNullOrJSON(this.Time5),
			Time6: convertDateToNullOrJSON(this.Time6),
			Time7: convertDateToNullOrJSON(this.Time7),
			Time8: convertDateToNullOrJSON(this.Time8),
			Time9: convertDateToNullOrJSON(this.Time9),
			Time10: convertDateToNullOrJSON(this.Time10),
			Time11: convertDateToNullOrJSON(this.Time11),
			Time12: convertDateToNullOrJSON(this.Time12),
			Time13: convertDateToNullOrJSON(this.Time13),
			Time14: convertDateToNullOrJSON(this.Time14),
			Time15: convertDateToNullOrJSON(this.Time15),
			Time16: convertDateToNullOrJSON(this.Time16),
			Time17: convertDateToNullOrJSON(this.Time17),
			Time18: convertDateToNullOrJSON(this.Time18),
			Time19: convertDateToNullOrJSON(this.Time19),
			Time20: convertDateToNullOrJSON(this.Time20),
		};
	}


	public getExtraFieldNamesConfig():ExtraFieldNamesConfig{
		return DossierConfigService.getReadonlyExtraFieldNamesConfigs().getContactNamesByGroupID(this.contactGroupId || 1);
	}

	public areSettingsEmpty(settings:ExtraFieldNamesConfig):boolean{
		let names = settings.getActiveFields();
		for (let name of names) {
			let val = (this as any)[name];
			if (name.startsWith("Num")){
				if (val != 0) {
					return false;
				}
			}else if (name.startsWith("Text")) {
				if (val != "") {
					return false;
				}
			}else if (name.startsWith("Bool")){
				if (val == true){
					return false;
				}
			}else if (name.startsWith("Date")) {
				if (val != null) {
					return false;
				}
			}
		}

		return true;
	}
}