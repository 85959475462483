import {
	dateIsWithinDaysAgo,
	dateIsWithinMonthsAgo,
	dateIsWithinYearsAgo,
	dateIsOverDaysAgo,
	dateIsOverMonthsAgo,
	dateIsOverYearsAgo,
	dateIsWithinDays,
	dateIsWithinMonths,
	dateIsWithinYears,
	dateIsOverDays,
	dateIsOverMonths,
	dateIsOverYears
} from "../date-utils";

export function conditionOperatorWithinDaysAgo(val1:any, val2:string):boolean {
	let date = new Date(val1);
	let num = parseInt(val2 || "0");
	if (Number.isNaN(num)){
		return false;
	}
	return dateIsWithinDaysAgo(date, num);
}
export function conditionOperatorWithinMonthsAgo(val1:any, val2:string):boolean {
	let date = new Date(val1);
	let num = parseInt(val2 || "0");
	if (Number.isNaN(num)){
		return false;
	}
	return dateIsWithinMonthsAgo(date, num);
}
export function conditionOperatorWithinYearsAgo(val1:any, val2:string):boolean {
	let date = new Date(val1);
	let num = parseInt(val2 || "0");
	if (Number.isNaN(num)){
		return false;
	}
	return dateIsWithinYearsAgo(date, num);
}
export function conditionOperatorOverDaysAgo(val1:any, val2:string):boolean {
	let date = new Date(val1);
	let num = parseInt(val2 || "0");
	if (Number.isNaN(num)){
		return false;
	}
	return dateIsOverDaysAgo(date, num);
}
export function conditionOperatorOverMonthsAgo(val1:any, val2:string):boolean {
	let date = new Date(val1);
	let num = parseInt(val2 || "0");
	if (Number.isNaN(num)){
		return false;
	}
	return dateIsOverMonthsAgo(date, num);
}
export function conditionOperatorOverYearsAgo(val1:any, val2:string):boolean {
	let date = new Date(val1);
	let num = parseInt(val2 || "0");
	if (Number.isNaN(num)){
		return false;
	}
	return dateIsOverYearsAgo(date, num);
}
export function conditionOperatorWithinDays(val1:any, val2:string):boolean {
	let date = new Date(val1);
	let num = parseInt(val2 || "0");
	if (Number.isNaN(num)){
		return false;
	}
	return dateIsWithinDays(date, num);
}
export function conditionOperatorWithinMonths(val1:any, val2:string):boolean {
	let date = new Date(val1);
	let num = parseInt(val2 || "0");
	if (Number.isNaN(num)){
		return false;
	}
	return dateIsWithinMonths(date, num);
}
export function conditionOperatorWithinYears(val1:any, val2:string):boolean {
	let date = new Date(val1);
	let num = parseInt(val2 || "0");
	if (Number.isNaN(num)){
		return false;
	}
	return dateIsWithinYears(date, num);
}
export function conditionOperatorOverDays(val1:any, val2:string):boolean {
	let date = new Date(val1);
	let num = parseInt(val2 || "0");
	if (Number.isNaN(num)){
		return false;
	}
	return dateIsOverDays(date, num);
}
export function conditionOperatorOverMonths(val1:any, val2:string):boolean {
	let date = new Date(val1);
	let num = parseInt(val2 || "0");
	if (Number.isNaN(num)){
		return false;
	}
	return dateIsOverMonths(date, num);
}
export function conditionOperatorOverYears(val1:any, val2:string):boolean {
	let date = new Date(val1);
	let num = parseInt(val2 || "0");
	if (Number.isNaN(num)){
		return false;
	}
	return dateIsOverYears(date, num);
}