import { getSaleMapPropSelectGroup } from "../prop-select/sale-map-prop-group";
import { Field } from "./field";

export class SaleLayoutTableLine extends Field {
	Position:number = 0.0;
	Width:number = 50.0;

	public constructor(data?:any){
		super(data);
		if (!data) {
			return;
		}
		this.Position = data.Position;
		this.Width = data.Width;
	}

	public getJSON():any{
		return {...super.getJSON(), Position: this.Position, Width: this.Width};
	}

	getText(lang:string):string{
		let text = this.Text.getTranslation(lang);
		if (!this.Prop) return text;
		let val = getSaleMapPropSelectGroup().getPropSelectFromValue(this.Prop);
		if (!val) return text;
		return text + val.text.toString();
	}

	getWidth():string{
		return `${this.Width}mm`;
	}
}