export interface ISocketMessage {
	event:string;
	data:any[];
	socketHost:string;
}

export interface ISocketAction {
	socketHost:string;
	method:string;
	args:any[];
	actionId?:string;
}

export const ERR_TOKEN_EXPIRED = "TOKEN_EXPIRED";