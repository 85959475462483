import { tokenStore } from "../token-store";
import { ERR_TOKEN_EXPIRED, ISocketAction, ISocketMessage } from "./socket-worker-interfaces";
import { v4 as uuid } from "uuid";
import { SocketServiceBase } from "./socket-service-base";
import { AuthService } from "@/services/auth-service";

let worker:SharedWorker | null = null;

let id = "";

export class SocketWorkerInterface extends SocketServiceBase{
	private shouldConnect = false;

	public constructor(host:string, path:string){
		super(host, path);
		if (!worker) return;
		worker.port.addEventListener("message", (event:MessageEvent)=>{
			let data:ISocketMessage = event.data;
			if (data.socketHost != this.host) return;
			if (data.event == "reconnect_attempt"){
				this.onReconnectAttempt();
			}
			if (data.event == "log"){
				this.log(data.data.join(" "));
			}
			this.triggerEvent(data.event, ...data.data);
		});
		this.on("/ready", ()=>{
			this.setIsConnected(true);
		});
		this.on("reconnect_attempt", ()=>{
			this.setIsConnected(false);
		});
		this.on("disconnect", async ()=>{
			this.setIsConnected(false);
			if (!this.shouldConnect) return;
			if (!tokenStore.tokenExpires) return;
			if (!tokenStore.willTokenExpireInSeconds(60)) {
				this.setAccessToken(tokenStore.getSelectedToken().token);
				return;
			}
			await AuthService.refreshTokens();
			this.setAccessToken(tokenStore.getSelectedToken().token);
			this.connect();
		});
		this.on("/init-error", (message:string)=>{
			console.log(this.host, "init-error", message);
			if (message == "TOKEN_EXPIRED" || message == "ERR_TOKEN_EXPIRED"){
				this.triggerEvent(ERR_TOKEN_EXPIRED);
			}
		});
	}

	broadcastToTabs(...data:any[]){
		this.postMessage({method: "broadcast-to-tabs", args: data, socketHost: this.host});
	}

	emit(event: string, ...data: any): Promise<any> {
		return new Promise((resolve)=>{
			let id = uuid();
			this.postMessage({socketHost: this.host, method: "emit", args: [event, ...data], actionId: id});
			this.once(`reply/${id}`, resolve);
		});
	}

	getId(): string {
		return id;
	}

	async onReconnectAttempt(){
		if (tokenStore.willTokenExpireInSeconds(10)){
			await AuthService.refreshTokens();
		}
		this.postMessage({socketHost: this.host, method: "updateQuery", args: [{token: tokenStore.accessTokens}]});
	}

	public setAccessToken(token: string): void {
		this.postMessage({method: "update-access-token", socketHost: this.host, args: [token]});
	}


	public async connect():Promise<void>{
		this.log("Connecting");
		this.shouldConnect = true;
		let options:SocketIOClient.ConnectOpts = {
			transports: ["websocket"],
			upgrade: false,
			path: this.path,
			reconnection: true,
			reconnectionAttempts: Infinity,
			reconnectionDelay: 1000,
			reconnectionDelayMax: 5000,
			// timeout: 10000,
			query: {}
		};
		return new Promise((resolve, reject)=>{
			let ref = {};
			this.once("/init-error", (message:string)=>{
				reject(new Error(message));
			}, ref);

			this.once("/ready", ()=>{
				resolve();
			}, ref);

			this.postMessage({socketHost: this.host, args: [options, tokenStore.getSelectedToken().token], method: "connect"});
		});
	}

	public async disconnect():Promise<void>{
		this.shouldConnect = false;
		this.postMessage({socketHost: this.host, method: "disconnect", args: []});
	}

	private postMessage(data:ISocketAction){
		if (!worker) return;
		worker.port.postMessage(data);
	}

	public static disconnectAll(){
		if (!worker) return;
		worker.port.postMessage("/disconnect-all");
	}
}

let getIdHandler = function(event:MessageEvent){
	if (!worker) return;
	id = event.data.id;
	worker.port.removeEventListener("message", getIdHandler);
};

if ((window as any).SharedWorker){
	worker = new SharedWorker(new URL("./socket-shared-worker.ts", import.meta.url));

	worker.addEventListener("error", (ev)=>{
		console.error(ev);
	});

	worker.port.addEventListener("message", getIdHandler);
	worker.port.addEventListener("message", (data:MessageEvent)=>{
		if (!data.data) return;
		if (data.data.event == "log"){
			console.info("SOCKET WORKER:", ...data.data.data);
		}
	});
	worker.port.start();
}