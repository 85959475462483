import { ISelectItem } from "winfakt-vue-components";
import { ExtraFieldBaseConfig } from "./extra-field-base-config";

export enum ExtraFieldsTextInputType {
	DEFAULT = "",
	AUTOCOMPLETE = "autocomplete",
	SELECT = "select",
	MULTI_SELECT = "multi-select"
}


export class ExtraFieldTextConfig extends ExtraFieldBaseConfig {
	DefaultValue:string = "";
	InputType:ExtraFieldsTextInputType = ExtraFieldsTextInputType.DEFAULT;
	InputMetaData:string = "";

	public constructor(data?:any){
		super(data);
		if (!data) return;
		this.DefaultValue = data.DefaultValue;
		this.InputType = data.InputType || "";
		this.InputMetaData = data.InputMetaData || "";
	}

	public getJSON(){
		return {
			...(super.getJSON()),
			DefaultValue: this.DefaultValue,
			InputType: this.InputType,
			InputMetaData: this.InputMetaData
		};
	}

	public static metadataToSelectItemList(input:string):ISelectItem<string>[]{
		return input.split("\n").map(v=>{
			let [text,value] = v.split("=");
			return {
				text: text || "",
				value: value || ""
			};
		});
	}

	public printValue(v:string):string {
		if (this.InputType == ExtraFieldsTextInputType.MULTI_SELECT) {
			while(v[0] == ";") {
				if (v[0] == ";"){
					v = v.substring(1);
				}
			}
			while(v[v.length-1] == ";"){
				if (v[v.length-1] == ";"){
					v = v.substring(0, v.length-1);
				}
			}
			return v.split(";").join(", ");
		}
		return v;
	}
}