import { BankCtepSaleResult } from "@/models/base/bank-ctep-sale-result";

export class ElectronCtepSaleResult {
	acquirerDiscretionaryData:string = "";
	acquirerId:string = "";
	acquirerToken:string = "";
	action:string = "";
	additionalAmount:number | null = null;
	authorizationCode:string = "";
	authorizedAmount:number = 0;
	cardBrandIdentifier:string = "";
	cardBrandName:string = "";
	clientTicket:string = "";
	clippedPan:string = "";
	isAsynchronousChip:boolean = false;
	isBarCode:boolean = false;
	isContactLess:boolean = false;
	isIccEmv:boolean = false;
	isIccNonEmv:boolean = false;
	isMagneticStripe:boolean = false;
	isManualEntry:boolean = false;
	isSynchronousChip:boolean = false;
	language:string = "";
	merchantText:string = "";
	merchantTicket:string = "";
	partialApproval:boolean | null = null;
	signatureRequired:boolean = false;
	terminalIdentifier:string = "";
	timeStamp:Date | null = null;

	public constructor(data:any){
		this.acquirerDiscretionaryData = data.acquirerDiscretionaryData;
		this.acquirerId = data.acquirerId;
		this.acquirerToken = data.acquirerToken;
		this.action = data.action;
		this.additionalAmount = data.additionalAmount;
		this.authorizationCode = data.authorizationCode;
		this.authorizedAmount = data.authorizedAmount;
		this.cardBrandIdentifier = data.cardBrandIdentifier;
		this.cardBrandName = data.cardBrandName;
		this.clientTicket = data.clientTicket;
		this.clippedPan = data.clippedPan;
		this.isAsynchronousChip = data.isAsynchronousChip;
		this.isBarCode = data.isBarCode;
		this.isContactLess = data.isContactLess;
		this.isIccEmv = data.isIccEmv;
		this.isIccNonEmv = data.isIccNonEmv;
		this.isMagneticStripe = data.isMagneticStripe;
		this.isManualEntry = data.isManualEntry;
		this.isSynchronousChip = data.isSynchronousChip;
		this.language = data.language;
		this.merchantText = data.merchantText;
		this.merchantTicket = data.merchantTicket;
		this.partialApproval = data.partialApproval;
		this.signatureRequired = data.signatureRequired;
		this.terminalIdentifier = data.terminalIdentifier;
		if (data.timeStamp) {
			this.timeStamp = new Date(data.timeStamp);
		}
	}

	public toModel():BankCtepSaleResult {
		let result = new BankCtepSaleResult();
		result.AcquirerID = this.acquirerId;
		result.AcquirerToken = this.acquirerToken;
		result.Action = this.action;
		result.AdditionalAmount = this.additionalAmount;
		result.AuthorizationCode = this.authorizationCode;
		result.AuthorizedAmount = this.authorizedAmount;
		result.CardBrandIdentifier = this.cardBrandIdentifier;
		result.CardBrandName = this.cardBrandName;
		result.ClientTicket = this.clientTicket;
		result.ClippedPan = this.clippedPan;
		result.IsAsynchronousChip = this.isAsynchronousChip;
		result.IsBarCode = this.isBarCode;
		result.IsContactLess = this.isContactLess;
		result.IsIccEmv = this.isIccEmv;
		result.IsIccNonEmv = this.isIccNonEmv;
		result.IsMagneticStripe = this.isMagneticStripe;
		result.IsManualEntry = this.isManualEntry;
		result.IsSynchronousChip = this.isSynchronousChip;
		result.Language = this.language;
		result.MerchantText = this.merchantText;
		result.MerchantTicket = this.merchantTicket;
		result.PartialApproval = this.partialApproval;
		result.SignatureRequired = this.signatureRequired;
		result.TerminalIdentifier = this.terminalIdentifier;
		result.TimeStamp = this.timeStamp;
		return result;
	}
}