import { ClientPortalEmailTemplate } from "./client-portal-email-template";
import { ClientPortalPaymentQrConfig } from "./client-portal-payment-qr-config";

export class ClientPortalConfig {
	Template:ClientPortalEmailTemplate = new ClientPortalEmailTemplate();
	PaymentQrCodeSettings:ClientPortalPaymentQrConfig = new ClientPortalPaymentQrConfig()
	ShownSaleTypeIDs:number[] = [];
	ShowEstimates:boolean = false;
	ShowActiveBookYear:boolean = false;

	public constructor(data?: any) {
		if(!data){
			return;
		}
		this.Template = new ClientPortalEmailTemplate(data.Template);
		this.PaymentQrCodeSettings = new ClientPortalPaymentQrConfig(data.PaymentQrCodeSettings);
		this.ShownSaleTypeIDs = [...data.ShownSaleTypeIDs || []];
		this.ShowActiveBookYear = data.ShowActiveBookYear || false;
		this.ShowEstimates = data.ShowEstimates || false;
	}

	public getJSON(): any {
		return {
			Template: this.Template.getJSON(),
			PaymentQrCodeSettings: this.PaymentQrCodeSettings.getJSON(),
			ShownSaleTypeIDs: [...this.ShownSaleTypeIDs],
			ShowActiveBookYear: this.ShowActiveBookYear,
			ShowEstimates: this.ShowEstimates
		};
	}
}