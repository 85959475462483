
import AppColorPicker from "@/components/ColorPicker.vue";
import {TextStyledProperty, TextAlignment, TextTransform, WhiteSpace} from "@/utils/documents/text-styled-property";
import { getEnumKeys, getEnumValueFromKey } from "@/utils/enum";
import Vue from "vue";
import { ISelectItem } from "winfakt-vue-components";
import AppStyleEditorBorder from "./StyleEditorBorder.vue";

export default Vue.extend({
	data() {
		return {
			toEditStyle: new TextStyledProperty(),
			tab: 0
		};
	},
	props: {
		value: {
			type: Object as () => TextStyledProperty,
			required: true
		},
		previewText: {
			type: String,
			required: false
		}
	},
	computed: {
		fonts(): ISelectItem<string>[] {
			return [
				"arial",
				"Comic Sans MS",
				"Georgia",
				"Open Sans",
				"Roboto",
				"Times New Roman",
				"verdana",
				"Tahoma"
			].map(v => {
				return {
					text: v,
					value: v
				};
			});
		},
		tabs(): any {
			return this.$ct("tabs.style");
		},
		textAlignItems(): ISelectItem<TextAlignment>[] {
			return [
				{
					text: this.$ct("align-left"),
					value: TextAlignment.LEFT,
					icon: "align-left"
				},
				{
					text: this.$ct("align-center"),
					value: TextAlignment.CENTER,
					icon: "align-center"
				},
				{
					text: this.$ct("align-right"),
					value: TextAlignment.RIGHT,
					icon: "align-right"
				},{
					text: this.$ct("justify"),
					value: TextAlignment.JUSTIFY,
					icon: "align-justify"
				}
			];
		},
		textTransformItems(): ISelectItem<TextTransform>[] {
			return [
				{
					text: this.$ct("none"),
					value: TextTransform.NONE,
				},
				{
					text: this.$ct("capitalize"),
					value: TextTransform.CAPITALIZE,
				},
				{
					text: this.$ct("uppercase"),
					value: TextTransform.UPPERCASE,
				},
				{
					text: this.$ct("lowercase"),
					value: TextTransform.LOWERCASE,
				},
				{
					text: this.$ct("full-width"),
					value: TextTransform.FULL_WIDTH,
				}
			];
		},
		allignedText(): ISelectItem<TextAlignment> {
			return this.textAlignItems.filter((t: any) => t.value == this.toEditStyle.TextAlign)[0];
		},
		transformedText(): ISelectItem<TextTransform> {
			return this.textTransformItems.filter((t: any) => t.value == this.toEditStyle.TextTransform)[0];
		},
		whiteSpaceItems():ISelectItem<WhiteSpace>[]{
			let keys = getEnumKeys(WhiteSpace);
			return keys.map(k=>{
				return {
					text: this.$ct(`white-space-item.${k}`),
					value: getEnumValueFromKey(WhiteSpace, k)
				};
			});
		}
	},
	watch: {
		value: {
			immediate: true,
			handler() {
				this.toEditStyle = new TextStyledProperty(this.value);
			}
		}
	},
	methods: {
		closeClicked() {
			this.$emit("close");
		},
		updateValue() {
			this.$emit("input", this.toEditStyle);
			this.$emit("close");
		}
	},
	components: {
		AppColorPicker,
		AppStyleEditorBorder
	},
	i18nEx: {
		componentPrefix: "components.layout-editor"
	}
});
