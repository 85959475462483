import { ServerConfig } from "@/config/config";
import { Job } from "@/models/job";
import { AuthService } from "./auth-service";
import { MainSocketService } from "./main-socket-service";
import { Axios } from "@/utils/axios";
import { EventEmitter } from "@/utils/event-emitter";
import { IInitAppResponse } from "./init-service";

export class JobServiceClass extends EventEmitter {
	private queuedJobs: number = 0;
	private myJobs: Job[] = [];
	private readonly url = ServerConfig.host + "/jobs";
	private readonly socketUrl = "/jobs/";
	private initialized:boolean = false;

	async fetchJobs(): Promise<void> {
		let result = await Axios.get(this.url);
		this.queuedJobs = result.data.JobCount;
		this.myJobs = result.data.MyJobs.map((j: any) => new Job(j));
		if (result.data.MyJobIsRunning) {
			this.myJobs[0].isRunning = true;
		}
	}
	async removeJobFromQueue(job: Job): Promise<void> {
		await Axios.delete(`${this.url}/${job.UserID}/${job.Name}`);
	}

	async cancelJob(job:Job):Promise<void>{
		await Axios.post(`${this.url}/${job.UserID}/cancel-current`);
		job.ShouldCancel = true;
	}

	async unCancelCurrent(job:Job):Promise<void>{
		await Axios.post(`${this.url}/${job.UserID}/un-cancel-current`);
		job.ShouldCancel = false;
	}

	private onStatusMessageUpdate(data:any) {
		let job = new Job(data);
		let j = this.myJobs.find(j=>j.getID() == job.getID());
		if (!j) {
			this.myJobs.push(job);
			return;
		}
		j.StatusMessage = job.StatusMessage;
		j.StatusMessageData = job.StatusMessageData;
	}

	private onProgressUpdate(data:any) {
		let job = new Job(data);
		let j = this.myJobs.find(j=>j.getID() == job.getID());
		if (!j) {
			this.myJobs.push(job);
			return;
		}
		j.Progress = job.Progress;
	}

	private onJobCancel(data:any){
		let job = new Job(data);
		let j = this.myJobs.find(j=>j.getID() == job.getID());
		if (!j) {
			return;
		}
		j.ShouldCancel = true;
	}
	private onJobUnCancel(data:any){
		let job = new Job(data);
		let j = this.myJobs.find(j=>j.getID() == job.getID());
		if (!j) {
			return;
		}
		j.ShouldCancel = false;
	}

	private onJobStart(data:any){
		let job = new Job(data);
		let index = this.myJobs.findIndex(j => j.getID() == job.getID());
		if (index != -1) {
			this.myJobs[index].isRunning = true;
		} else {
			job.isRunning = true;
			index = this.myJobs.push(job) -1;
		}
		this.emit(`job-started/${job.getID()}`, this.myJobs[index]);
	}

	private onJobStop(data:any){
		let job = new Job(data);
		let index = this.myJobs.findIndex(j => j.getID() == job.getID());
		if (index != -1) {
			this.myJobs.splice(index, 1);
			this.emit(`job-stopped/${job.getID()}`, job);
		}
	}

	public getJobs():Job[]{
		return this.myJobs;
	}

	public getQueuedJobs():number{
		return this.queuedJobs;
	}

	init(data:IInitAppResponse) {
		if (!this.initialized) {
			AuthService.on("logout", () => {
				this.queuedJobs = 0;
				this.myJobs = [];
			});

			MainSocketService.on(`${this.socketUrl}queue`, (data: number) => {
				this.queuedJobs = data;
			});

			MainSocketService.on(this.socketUrl + "queued", (data: any) => {
				let job = new Job(data);
				this.myJobs.push(job);
			});
			this.initialized = true;
			MainSocketService.on(this.socketUrl + "start", this.onJobStart.bind(this));
			MainSocketService.on(this.socketUrl + "stop", this.onJobStop.bind(this));
			MainSocketService.on(this.socketUrl + "status-message", this.onStatusMessageUpdate.bind(this));
			MainSocketService.on(this.socketUrl + "progress", this.onProgressUpdate.bind(this));
			MainSocketService.on(this.socketUrl + "canncel", this.onJobCancel.bind(this));
			MainSocketService.on(this.socketUrl + "uncanncel", this.onJobUnCancel.bind(this));
		}

		this.queuedJobs = data.JobCount;
		this.myJobs = data.MyJobs.map((j: any) => new Job(j));
		if (data.MyJobIsRunning) {
			this.myJobs[0].isRunning = true;
		}
	}
};

export const JobService = new JobServiceClass();
