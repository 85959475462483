import { ServerConfig } from "@/config/config";
import { Contact, Sale } from "@/models/base";
import { UnauthenticatedClient as Axios } from "@/utils/axios";
import { PdfImages } from "@/utils/pdf-images";
import { ISaleFile, ISaleImageFile } from "./sale-service";

const URL = `${ServerConfig.host}/public/client-portal`;
const PUBLIC_SALE_URL = `${ServerConfig.host}/public/sale`;

export interface IClientPortalData {
	Client:Contact;
	OpenSales:Sale[];
	PaidSales:Sale[];
	ExpiredSales:Sale[];
	OpenEstimates:Sale[];
	ClosedEstimates:Sale[];
	OpenSaleRecords:number;
	OpenEstimateRecords:number;
	PaidSaleRecords:number;
	CanPayWithMollie:boolean;
	HighlightSale:number;
	ShowEstimates: boolean;
}

export enum MollieAutomaticPaymentStatus {
	UNAVAILABLE = 0,
	NOT_CONFIGURED = 1,
	CONFIGURED = 2
}

export interface ISalePublicUrlResponse{
	sale:Sale,
	canPayWithMollie:boolean
	canPayWithQrCode:boolean
	canSign:boolean
	mollieAutomaticPaymentStatus:MollieAutomaticPaymentStatus
	token:string
}

export class ClientPortalServiceClass {
	public async getClientPortalData(token:string, offset:number = 0):Promise<IClientPortalData>{
		let result = await Axios.get(`${URL}/${token}`, {params: {
			offset
		}});
		return {
			Client: new Contact(result.data.Client),
			OpenSales: result.data.OpenSales.map((d:any)=>new Sale(d)),
			PaidSales: result.data.PaidSales.map((d:any)=>new Sale(d)),
			ExpiredSales: result.data.ExpiredSales.map((d:any)=>new Sale(d)),
			OpenSaleRecords: result.data.OpenSaleRecords,
			OpenEstimates: result.data.OpenEstimates.map((d:any)=>new Sale(d)),
			ClosedEstimates: result.data.ClosedEstimates.map((d:any)=>new Sale(d)),
			OpenEstimateRecords: result.data.OpenEstimateRecords,
			PaidSaleRecords: result.data.PaidSaleRecords,
			CanPayWithMollie: result.data.CanPayWithMollie,
			HighlightSale: result.data.HighlightSale,
			ShowEstimates: result.data.ShowEstimates
		} as IClientPortalData;
	}

	public async getSalePublicToken(token:string, saleId:number):Promise<string>{
		let result = await Axios.get(`${URL}/${token}/sale/${saleId}`);
		return result.data.Token;
	}

	public async getSaleFromPublicToken(token:string):Promise<ISalePublicUrlResponse>{
		let result = await Axios.get(`${PUBLIC_SALE_URL}/${token}`);
		let sale = new Sale(result.data.Sale);
		let canPayWithMollie = result.data.CanPayWithMollie as boolean || false;
		let canPayWithQrCode = result.data.CanPayWithQrCode as boolean || false;
		let canSign = result.data.CanSign as boolean || false;
		let mollieAutomaticPaymentStatus = result.data.MollieAutomaticPaymentStatus || MollieAutomaticPaymentStatus.UNAVAILABLE;

		return { sale, canPayWithMollie, canPayWithQrCode, canSign, token, mollieAutomaticPaymentStatus };
	}

	public getSalePaymentQrCodeUrl(token:string):string{
		return `${PUBLIC_SALE_URL}/${token}/pay-with-qr-image`;
	}

	public async downloadSaleAsBlob(token:string):Promise<ISaleFile>{
		let result = await Axios.get(`${PUBLIC_SALE_URL}/${token}/download`);
		return result.data;
	}

	public async downloadSaleAsImages(token:string):Promise<ISaleImageFile>{
		let result = await Axios.get(`${PUBLIC_SALE_URL}/${token}/download?img=true`);
		result.data.Data = new PdfImages(result.data.Data);
		return result.data;
	}

	public async paySale(saleUrlToken:string, options?:{enableRecurringPayment?:boolean}):Promise<void>{
		let data = {
			EnableRecurring: false
		};
		if (options) {
			data.EnableRecurring = options.enableRecurringPayment || false;
		}
		let result = await Axios.post(`${PUBLIC_SALE_URL}/${saleUrlToken}/pay`, data,{
			headers: {"X-Custom-Referer": window.location.href},
		});
		window.open(result.data.Url);
	}

	public async signSale(saleUrlToken:string, signedBy:string, blob:Blob):Promise<Sale>{
		let data = new FormData();
		data.append("file", blob, "signature.png");
		data.append("name", signedBy);
		let result = await Axios.post(`${PUBLIC_SALE_URL}/${saleUrlToken}/sign`, data);
		return new Sale(result.data);
	}

	public async disableRecurringPayments(saleUrlToken:string):Promise<void>{
		await Axios.post(`${PUBLIC_SALE_URL}/${saleUrlToken}/cancel-automatic-payments`);
	}

}

export const ClientPortalService = new ClientPortalServiceClass();