
import Vue from "vue";
import { ISelectItem } from "winfakt-vue-components";
import { DocumentPageSettings } from "../../utils/documents/page-settings";


interface IFormat {
	width: number;
	height: number;
}


export default Vue.extend({
	data(){
		return {
			useCustomFormat: false
		};
	},
	props: {
		value: {
			type: Object as () => DocumentPageSettings,
			required: true,
		}
	},
	computed: {
		// eslint-disable-next-line max-lines-per-function
		pageFormatItems(): ISelectItem<IFormat>[] {
			return [
				{
					text: this.$ct("formats.paperFormat", {format: "A3"}).toString(),
					value: {
						width: 297,
						height: 420
					} as IFormat
				},
				{
					text: this.$ct("formats.paperFormat", {format: "A4"}).toString(),
					value: {
						width: 210,
						height: 297
					} as IFormat
				},
				{
					text: this.$ct("formats.paperFormat", {format: "A5"}).toString(),
					value: {
						width: 148,
						height: 210
					} as IFormat
				},
				{
					text: this.$ct("formats.paperFormatLandscape", {format: "A3"}).toString(),
					value: {
						height: 297,
						width: 420
					} as IFormat
				},
				{
					text: this.$ct("formats.paperFormatLandscape", {format: "A4"}).toString(),
					value: {
						height: 210,
						width: 297
					} as IFormat
				},
				{
					text: this.$ct("formats.paperFormatLandscape", {format: "A5"}).toString(),
					value: {
						height: 148,
						width: 210
					} as IFormat
				},
			];
		},
		selectedFormat(): IFormat {
			let result = this.pageFormatItems.find(v => this.compareFormat(v.value));
			if (!result) {
				return {
					width: this.value.Width,
					height: this.value.Height
				};
			}
			return result.value;
		},
	},
	methods: {
		setFormat(format: IFormat) {
			if (!format)return;
			this.value.Width = format.width;
			this.value.Height = format.height;
		},
		compareFormat(format:IFormat): boolean {
			return format.width == this.value.Width && format.height == this.value.Height;
		},
		doesFormatExist(format:IFormat):boolean {
			for (let item of this.pageFormatItems) {
				if (item.value.width != format.width){
					continue;
				}
				if (item.value.height != format.height) {
					continue;
				}
				return true;
			}
			return false;
		},
		useDefaultFormat(){
			this.useCustomFormat = false;
			if (this.doesFormatExist({height: this.value.Height, width: this.value.Width})){
				return;
			}
			this.value.Width = 210;
			this.value.Height = 297;
		}
	},
	mounted(){
		if (!this.doesFormatExist({height: this.value.Height, width: this.value.Width})) {
			this.useCustomFormat = true;
		}
	},
	i18nEx: {
		componentPrefix: "components.document-settings"
	}
});
