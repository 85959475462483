import { DashboardTab } from "@/utils/dashboard/dashboard-tab";

export class DashboardSettings {
	SubtractPurchasesFromProfit:boolean = false;
	SubtractSaleBuyPriceFromProfit:boolean = false;
	ToComputeSaleTypes:number[] = [];
	Tabs:DashboardTab[] = [];
	LastOpenedTabIndex:number = 0;

	public constructor(data?:any){
		this.SubtractPurchasesFromProfit = data.SubtractPurchasesFromProfit;
		this.SubtractSaleBuyPriceFromProfit = data.SubtractSaleBuyPriceFromProfit;
		this.ToComputeSaleTypes = [...data.ToComputeSaleTypes || []];
		this.Tabs = (data.Tabs as any[] || []).map(d=>new DashboardTab(d));
		this.LastOpenedTabIndex = data.LastOpenedTabIndex || 0;
		if (this.LastOpenedTabIndex >= this.Tabs.length){
			this.LastOpenedTabIndex = 0;
		}
	}

	public getJSON(){
		return {
			SubtractPurchasesFromProfit: this.SubtractPurchasesFromProfit,
			SubtractSaleBuyPriceFromProfit: this.SubtractSaleBuyPriceFromProfit,
			ToComputeSaleTypes: [...this.ToComputeSaleTypes],
			Tabs: this.Tabs.map(t=>t.getJSON()),
			LastOpenedTabIndex: this.LastOpenedTabIndex
		};
	}

	/*
	public getItemList():DashboardItem[] {
		let result:DashboardItem[] = [
			this.TotalProfit,
			this.TotalOpen,
			this.TotalRevenue,
			this.OpenClients,
			this.RevenuePerMonthCompare,
			this.RevenuePerMonthTotal,
			this.CashInFlow,
			this.TaskList,
			this.Calendar,
		];

		for (let item of this.CustomItems){
			result.push(item);
		}

		return result;
	}
	*/

}