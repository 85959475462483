import { ServerConfig } from "@/config/config";
import { CalculatedField } from "@/models/calculated-field";
import { View } from "@/models/view";
import { Axios } from "@/utils/axios";
import { Model } from "@/utils/models/model";
import { IInitAppResponse } from "./init-service";

export class ViewServiceClass{

	get url():string{
		return `${ServerConfig.host}/view`;
	}

	async getView(viewID: number): Promise<View> {
		let result = await Axios.get(`${this.url}/${viewID}`);
		return new View(result.data);
	}

	async updateView(view: View): Promise<View> {
		view.computePreloads();
		let result = await Axios.put(`${this.url}`, [view.getJSON()]);
		return result.data.map((d: any) => new View(d))[0];
	}

	async createView(view: View): Promise<View> {
		return (await this.createViews([view]))[0];
	}

	async createViews(views:View[]):Promise<View[]>{
		views.forEach(v=>v.computePreloads());
		let result = await Axios.post(`${this.url}`, views.map(v=>v.getJSON()));
		return result.data.map((d: any) => new View(d));
	}

	async updateViews(views: View[]): Promise<View[]> {
		let data = views.map(v => {
			v.computePreloads(); return v.getJSON();
		});
		let result = await Axios.put(`${this.url}`, data);
		return result.data.map((d: any) => new View(d));
	}

	async deleteView(view: View,forceDeleteInsteadOfUnshare:boolean = false): Promise<void> {
		await Axios.delete(`${this.url}/${view.ID}?force=${forceDeleteInsteadOfUnshare}`);
	}

	async deleteViews(views:View[], forceDeleteInsteadOfUnshare:boolean = false):Promise<void>{
		await Axios.delete(`${this.url}?force=${forceDeleteInsteadOfUnshare}`, {
			data: views.map(v=>v.ID)
		});
	}

	async restoreViews() {
		await Axios.post(ServerConfig.host + "/view/restore-all");
	}

	async getUsersViews(userId:number):Promise<View[]>{
		let result = await Axios.get(`${this.url}/by-user/${userId}`);
		return result.data.map((r:any)=>new View(r));
	}

	async restoreUserViews(userId:number){
		await Axios.post(`${ServerConfig.host}/view/restore-all-for-user/${userId}`);
	}

	async setColumnWidths(viewId:number, widths:string[]):Promise<View>{
		let result = await Axios.post(`${this.url}/${viewId}/set-column-widths`, widths);
		return new View(result.data);
	}

	async getDefaultViewSettings(browseId:number, viewIndex:number):Promise<View>{
		let result = await Axios.get(`${this.url}/get-default-view-settings/${browseId}/${viewIndex}`);
		return new View(result.data);
	}

	async restoreDefaultSettings(view:View):Promise<View>{
		if (!view.hasDefaultSettings()) {
			throw new Error("No default view settings");
		}
		let defaultSettings = await this.getDefaultViewSettings(view.BrowseID, view.getDefaultSettingsIndex());
		defaultSettings.ID = view.ID;
		defaultSettings.UserID = view.UserID;
		defaultSettings.DisplayOrder = view.DisplayOrder;
		view = await this.updateView(defaultSettings);
		return view;
	}

	async loadCalculatedFields():Promise<void>{
		let res = await Axios.get(`${this.url}/calculated-fields`);
		let fields = ((res.data || []) as any[]).map((c:any)=>new CalculatedField(c));
		Model.setCalculatedFields(fields);
	}

	// returns a list of user ids
	async getShares(view:View):Promise<number[]>{
		let res = await Axios.get(`${this.url}/${view.ID}/shares`);
		return res.data || [];
	}

	async setShares(view:View, sharedUserIds:number[], unshareUserIds:number[]):Promise<View> {
		let res = await Axios.post(`${this.url}/${view.ID}/set-shares`, {
			ShareUserIDs: sharedUserIds,
			UnshareUserIDs: unshareUserIds
		});
		return new View(res.data);
	}

	init(data:IInitAppResponse){
		Model.setCalculatedFields(data.CalculatedFields);
	}
};

export const ViewService = new ViewServiceClass();