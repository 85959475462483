import { ISaleStatus, SaleStatus } from "@/models/base/sale";
import { i18n } from "@/setup/i18n-setup";
import CrmStatus from "@/models/crm/crm-status";
import { durationToString } from "./duration";
import { UserConfigService } from "@/services/user-config-service";
import { DossierService } from "@/services/dossier-service";
import { DossierConfigService } from "@/services/dossier-config-service";
import { icon as faIcon, IconName } from "@fortawesome/fontawesome-svg-core";

export function printHumanPurchaseFloat(value: any, decimals: number = 2): string {
	let CanReadProductPurchaseDetails = UserConfigService.hasCurrentUserPermission((p)=>p.CanReadProductPurchaseDetails);

	if(!CanReadProductPurchaseDetails){
		return "";
	}

	if (!value) {
		return (0).toLocaleString("nl-be", {minimumFractionDigits: decimals, maximumFractionDigits: decimals});
	}
	let num = value;
	if (typeof (value) == "string") {
		num = parseFloat(value);
	}
	if (typeof (num) == "number") {
		return num.toLocaleString("nl-be", {minimumFractionDigits: decimals, maximumFractionDigits: decimals});
	}
	return num;
}

export function printHumanFloat(value: any, decimals: number = 2): string {
	if (!value) {
		return (0).toLocaleString("nl-be", {minimumFractionDigits: decimals, maximumFractionDigits: decimals});
	}
	let num = value;
	if (typeof (value) == "string") {
		num = parseFloat(value);
	}
	if (typeof (num) == "number") {
		return num.toLocaleString("nl-be", {minimumFractionDigits: decimals, maximumFractionDigits: decimals});
	}
	return num;
}

export function printHumanFloatDashboard(value:any):string{
	if (!value){
		return (0).toLocaleString("nl-be", {minimumFractionDigits: 2, maximumFractionDigits: 2});
	}
	let num = value;
	if (typeof (value) == "string") {
		num = parseFloat(value);
	}
	if (num>10000){
		return num.toLocaleString("nl-be", {minimumFractionDigits: 0, maximumFractionDigits: 0});
	}
	return printHumanFloat(num);
}

export function cleanHtml(value: any): string {
	return value.replaceAll("{__NEW_LINE__}","<br>");
}

export function printHumanFloatPercentage(value:any, decimals: number = 2):string{
	return `${printHumanFloat(value, decimals)}%`;
}

export function printHumanInt(value: any): string {
	// if (!value) return "N/A";
	let num = value;
	if (typeof (value) == "string") {
		num = parseInt(value);
	}
	return num + "";
}

export function printLocalDate(value: Date | null): string {
	if (!value) {
		return i18n.t("common.none").toString();
	}
	return value.toLocaleDateString("nl-be", {year: "numeric", month: "2-digit", day: "2-digit"});
}

export function printLocalDateTime(value: Date | null): string {
	if (!value) {
		return i18n.t("common.none").toString();
	}
	return value.toLocaleDateString("nl-be", {year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit"});
}

export function printWithCustomFormat(value:Date | null, format:string):string {
	if (!value) {
		return i18n.t("common.none").toString();
	}
	function prefixWithZero(num:number):string {
		let res = `${num}`;
		if (res.length == 1){
			return `0${num}`;
		}
		return res;
	}
	let values = {
		DD: prefixWithZero(value.getDate()),
		MM: prefixWithZero(value.getMonth() + 1),
		YYYY: prefixWithZero(value.getFullYear()),
		hh: prefixWithZero(value.getHours()),
		mm: prefixWithZero(value.getMinutes()),
		ss: prefixWithZero(value.getSeconds()),
		D: `${value.getDate()}`,
		M: `${value.getMonth()+1}`,
		h: `${value.getHours()}`,
		m: `${value.getMinutes()}`,
		s: `${value.getSeconds()}`,
	};
	let result = format;
	for (let key of Object.keys(values)){
		result = result.replaceAll(`{${key}}`, (values as any)[key]);
	}
	return result;
}

export function printLongLocalDateTime(value: Date | null): string {
	if (!value) {
		return i18n.t("common.none").toString();
	}
	return value.toLocaleDateString("nl-be", {weekday: "long",year: "numeric", month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit"});
}

export function highlightCell(value: any): string {
	return parseFloat(value) != 0 ? "<span class='highlight'>" + value + "</span>" : value;
}

export function printSaleStatus(value: ISaleStatus): string {
	if(value.Status == SaleStatus.Empty) {
		return "";
	}

	if (value.Status == SaleStatus.Payed) {
		return "<span class='st payed'>" + i18n.t("common.sales.status.payed").toString() + "<span>";
	}

	if (value.Status == SaleStatus.Difference) {
		return "<span class='st difference'>" + i18n.t("common.sales.status.difference").toString() + "<span>";
	}

	if (value.Status == SaleStatus.Unpayed) {
		return "<span class='st unpayed'>" + i18n.t("common.sales.status.unpayed").toString() + "<span>";
	}

	if (!value.DaysToLate) {
		return "ERROR";
	}
	let val = "<span class='st expired'>" + i18n.t("common.sales.status.expired").toString();
	val += " (" + Math.round(value.DaysToLate) + " " + i18n.t("common.sales.status.days").toString() + ")<span>";
	return val;
}
export function printCrmStatus(value: CrmStatus): string {
	return "<span class='st crm_status status_"+value.ID+"'>" + value.Name + "<span>";
}
export function printDayDiff(value: any): string {

	let myDate: any = new Date();
	let dayDiff = (myDate - value) / (1000 * 60 * 60 * 24);

	if (dayDiff > 0) {
		let val = i18n.t("common.sales.status.expired").toString();
		val + " (" + Math.round(dayDiff) + " " + i18n.t("common.sales.status.days").toString() + ")";
		return val;
	}
	return "";
}

export function printBoolean(value:boolean):string {
	if (value) {
		return i18n.t("common.yes").toString();
	}
	return i18n.t("common.no").toString();
}

export function printDuration(value:number):string {
	return durationToString(value);
}

export function printImage(url:string):string{
	if (!url){
		return "";
	}
	return `<img style="max-height:40px;margin-top:-12px;margin-bottom:-15px" src="${url}" name="image-from-record"/>`;
}

export function printUser(id:number):string {
	if (id == 0){
		return i18n.t("common.none").toString();
	}
	let user = DossierService.getUser(id);
	return user.getUserString();
}

export function sellPriceCategory(category:number):string {
	console.log("Printing sell category");
	return DossierConfigService.getSellCategories().getCategoryName(category);
}


export function printBooleanWithIcon(v:boolean, iconTrue:string, iconTrueColor:string, iconFalse:string, iconFalseColor:string):string {
	if (v && iconTrue) {
		let icon = faIcon({prefix: "fas", iconName: iconTrue as IconName}).html;
		let div = document.createElement("div");
		div.innerHTML = icon[0];
		(div.firstChild as HTMLElement).style.color = iconTrueColor;
		return div.innerHTML;
	}
	if (!v && iconFalse) {
		let icon = faIcon({prefix: "fas", iconName: iconFalse as IconName}).html;
		let div = document.createElement("div");
		div.innerHTML = icon[0];
		(div.firstChild as HTMLElement).style.color = iconFalseColor;
		return div.innerHTML;
	}
	return printBoolean(v);
}

export function printWithoutHtmlTags(value:string):string{
	let result = value.replaceAll("{__NEW_LINE__}", " ").replaceAll("{__DOUBLE_SPACE__}", "&nbsp;");
	result = result.replaceAll("<br>", " ");
	result = result.replaceAll("<p>", "").replaceAll("</p>", " ");
	result = result.replaceAll("<blockquote>", "").replaceAll("</blockquote>", " ");
	return result;
}