import { i18n } from "@/setup/i18n-setup";
import { Condition, Operator } from "@/utils/condition/condition";
import { getModel } from "@/utils/models/model";
import { v4 } from "uuid";
import SaleRow from "./sale-row";

export class ContactPriceRule {
	key:string = v4();

	ID:number = 0;
	ContactID:number = 0;
	Filters:Condition[] = [];
	Price:number = 0.0;
	Discount:number = 0.0;
	DisplayOrder:number = 0;
	PriceCategory:number = 0;

	public constructor(data?:any) {
		if (!data) return;
		this.ID = data.ID;
		this.ContactID = data.ContactID;
		this.Filters = (data.Filters || []).map((f:any)=>new Condition(f));
		this.Price = data.Price;
		this.Discount = data.Discount || 0;
		this.DisplayOrder = data.DisplayOrder || 0;
		this.PriceCategory = data.PriceCategory;
	}

	public getJSON(){
		return {
			ID: this.ID,
			ContactID: this.ContactID,
			Filters: this.Filters.map(f=>f.getJSON()),
			Price: this.Price,
			Discount: this.Discount,
			DisplayOrder: this.DisplayOrder,
			PriceCategory: this.PriceCategory
		};
	}

	public static filterToString(filter:Condition):string {
		let model = getModel("SaleRow");
		let field = model.getField(filter.Prop);
		if (!field) {
			return "ERROR";
		}
		let operator = i18n.t(`common.condition.short-operators.${filter.Operator}`);
		if (filter.Operator == Operator.IS_EMPTY || filter.Operator == Operator.IS_NOT_EMPTY) {
			return `${field.getPropertyTranslation()} ${operator}`;
		}
		return `${field.getPropertyTranslation()} ${operator} ${filter.Value}`;
	}

	public toString():string{
		let result = "";
		for (let i in this.Filters) {
			let filter = this.Filters[i];
			let str = ContactPriceRule.filterToString(filter);
			if (i != "0") {
				if (filter.IsOr) {
					result += ` ${i18n.t("common.or")} `;
				}else{
					result += ` ${i18n.t("common.and")} `;
				}
			}
			result += str;
		}
		if(this.Discount != 0){
			return i18n.t("components.contacts.discount-rule-as-text", {discount: this.Discount.toFixed(2), rules: result}).toString();
		}
		return i18n.t("components.contacts.price-rule-as-text", {price: this.Price.toFixed(2), rules: result}).toString();
	}

	public test(val:SaleRow):boolean {
		return Condition.checkConditions(this.Filters, val);
	}

	private static filterToNonTranslatedString(filter:Condition):string {
		let operator = `${filter.Operator}`;
		return `${filter.Value} ${operator} ${filter.Value}`;
	}

	private toUntranslatedString():string{
		let result = "";
		for (let i in this.Filters) {
			let filter = this.Filters[i];
			let str = ContactPriceRule.filterToNonTranslatedString(filter);
			if (i != "0") {
				if (filter.IsOr) {
					result += ` or `;
				}else{
					result += ` and `;
				}
			}
			result += str;
		}
		return `${this.Price} if ${result}`;
	}

	public compare(rule:ContactPriceRule):boolean {
		return rule.toUntranslatedString() == this.toUntranslatedString();
	}
}