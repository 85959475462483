import {ServerConfig} from "@/config/config";
import {Axios} from "@/utils/axios";

export class ShopServiceClass{
	get url(): string {
		return ServerConfig.host + "/shop";
	}

	async update(id:number){
		await Axios.post(`${this.url}/update/${id}`,{});
	}
};

export const ShopService = new ShopServiceClass();