import { i18n } from "@/setup/i18n-setup";
import { CustomTranslation } from "@/utils/custom-translation";
import { field, hidden, notFilterable, print } from "@/utils/models/decorator-field";
import { model } from "@/utils/models/decorator-model";

@model("SaleType")
export default class SaleType {
	@field("number")
	@notFilterable()
	public ID:number = 0;

	@field("json")
	@print((value: CustomTranslation): string => value.getTranslation(i18n.locale))
	@hidden()
	public Name:CustomTranslation = new CustomTranslation();

	@field("string")
	@notFilterable()
	public DisplayedName:string = "";

	@field("number")
	public ContactGroupID:number = 0;

	public constructor(data?: any) {
		if (!data) {
			return;
		}
		this.ID = data.ID;
		this.Name = new CustomTranslation(data.Name);
		this.DisplayedName = data.DisplayedName;
		this.ContactGroupID = data.ContactGroupID;
	}

	public getJSON() {
		return {
			ID: this.ID,
			Name: this.Name.getJSON(),
			DisplayedName: this.DisplayedName,
			ContactGroupID: this.ContactGroupID,
		};
	}

	public onUpdate(newSaleType:SaleType){
		this.Name = newSaleType.Name;
		this.DisplayedName = newSaleType.DisplayedName;
	}

	getDisplayedName(locale:string):string {
		if (this.DisplayedName != "") {
			return this.DisplayedName;
		}
		return this.Name.getTranslation(locale);
	}

	canBeDeleted():boolean {
		return this.ID > 2 && this.ID != 10;
	}
}
