
import AppProductBrowse from "@/components/products/ProductSearchBrowse.vue";
import { Product } from "@/models/base";
import { ProductService } from "@/services/product-service";
import {v4 as uuid} from "uuid";
import Vue from "vue";
import {DossierConfigService, UserConfigService, ViewService} from "@/services";
import {View} from "@/models/view";

export default Vue.extend({
	data() {
		return {
			searchProductModal: false,
			foundProducts: [] as Product[],
			text: "",
			realTimeText: "",
			fetchingThread: "",
			searchThread: "",
			searchString: "",
			onReadyToBlurHandlers: [] as Function[],
			disableButtons: false,
			enterPressed: false,
			wait: UserConfigService.getSaleSettings().WaitForEnterOnProductSearch,
			view: null as View | null,
			generalSettings: UserConfigService.getGeneralSettings(),
			stockSettings: DossierConfigService.getStockLocationConfig()
		};
	},
	props: {
		value: Object as () => Product,
		label: [Object, String],
		autoFocus: Boolean,
		includeDisabled: Boolean,
		disabled: Boolean,
		allowMultiple: Boolean,
		removeOnEmpty: Boolean,
		showStockLocation: Number
	},
	computed: {
		compLabel(): string {
			if (this.label) {
				if (typeof (this.label) == "string") {
					return this.label;
				}
				return this.label.toString();
			}
			return this.$ct("product-sku").toString();
		},
		isOpened():boolean {
			let el = this.$refs.productSku as any;
			if (!el) return false;
			return el.isOpened && this.foundProducts.length > 0;
		},
		toShowStockLocationName():string{
			if (!this.showStockLocation) return "";
			return this.stockSettings.getLocationName(this.showStockLocation);
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(product: Product) {
				if (!product) {
					this.text = "";
					return;
				};
				this.text = product.Sku || "";
			}
		},
		async text(text: string) {
			this.realTimeText = text;
			this.$emit("text", text);
			if (text.match(/^\s*$/)) {
				this.foundProducts = [];
				return;
			}
			if (this.value && this.value.Sku == this.text) {
				return;
			}
			let threadId = uuid();
			this.searchThread = threadId;
			if(!this.wait){
				let foundProducts = await ProductService.search(text, this.includeDisabled);
				if (threadId != this.searchThread) return;
				this.foundProducts = foundProducts;
			}
		},
		fetchingThread(){
			if (this.fetchingThread){
				this.$emit("fetching", true);
			}else{
				this.$emit("fetching", false);
			}
		}
	},
	methods: {
		getStockQtyText(p:Product):string{
			if (!this.toShowStockLocationName) return "";
			if (!p.Stock) return "";
			try{
				return `${this.toShowStockLocationName}: ${p.Stock.getStockAtLocation(this.showStockLocation)}`;
			}catch(err){

			}
			return "";
		},
		clean(value:string){
			let v = value.replace( /(<([^>]+)>)/ig, " ");
			v = v.replaceAll("{__NEW_LINE__}", " ").replaceAll("{__DOUBLE_SPACE__}", "&nbsp;");
			return v.length > 250 ? v.substring(0,250) + "..." : v;
		},
		focus() {
			(this.$refs.productSku as any).focus();
		},
		async searchOnEnter(){
			let foundProducts = [];
			this.foundProducts = [];
			if (this.realTimeText.match(/^\s*$/)) {
				this.foundProducts = [];
				return;
			}
			if (this.value && this.value.Sku == this.realTimeText) {
				this.$emit("blur");
				return;
			}
			foundProducts = await ProductService.search(this.realTimeText, this.includeDisabled);
			if(foundProducts.length == 1 && foundProducts[0].Sku?.toUpperCase() == this.realTimeText.toUpperCase()){
				await this.productSelected(foundProducts[0]);
				this.fetchingThread= "";
			}else{
				this.foundProducts = foundProducts;
			}
			this.enterPressed = false;
		},
		keyup(event: KeyboardEvent) {
			if(event.key == "Enter"){
				this.enterPressed = true;
				this.searchOnEnter();
				return;
			}
			if(event.key == "F3"){
				this.searchProducts();
			}
			if(this.wait && event.key != "ArrowDown" && event.key != "ArrowUp"){
				this.foundProducts = [];
			}
			this.$emit("keyup", event);
		},
		onRealTimeValue(text:string){
			this.realTimeText = text;
		},
		async modalProductSelected(product: Product | Product[]) {
			if (Array.isArray(product)){
				if (product.length > 1)  {
					for (let i = 0; i < product.length; i++){
						product[i] = await ProductService.getProduct(product[i].ID);
					}
					this.$emit("inputs", product);
					this.close();
					return;
				}
				product = product[0];
			}
			await this.productSelected(product);
			this.close();
		},
		async productSelected(product: Product) {
			let threadId = uuid();
			this.fetchingThread = threadId;

			try{
				product = await ProductService.getProduct(product.ID);
				this.text = "";
				this.$emit("input", product);
			}catch(err){
			}

			if (threadId != this.fetchingThread) return;
			this.fetchingThread = "";
			for (let i = this.onReadyToBlurHandlers.length - 1; i>=0; i--){
				this.onReadyToBlurHandlers[i]();
				this.onReadyToBlurHandlers.pop();
			}
		},
		async blur() {
			if (this.searchProductModal) return;
			this.text = this.realTimeText;
			if (this.removeOnEmpty && this.text == ""){
				this.$emit("input", null);
			}
			await this.isReadyToBlur();
			if(!this.wait){
				this.$emit("blur");
			}
		},
		cancelSelectProduct() {
			this.searchString = "";
			this.searchProductModal = false;
		},
		searchProducts() {
			if(this.disabled){
				return;
			}
			this.searchString = this.realTimeText;
			this.searchProductModal = true;
		},
		clear() {
			this.text = "";
		},
		close() {
			this.searchString = "";
			this.searchProductModal = false;
		},
		async isReadyToBlur():Promise<void>{
			if (!this.fetchingThread) {
				return;
			}
			return new Promise((resolve)=>{
				if (!this.onReadyToBlurHandlers){
					return resolve();
				}
				this.onReadyToBlurHandlers.push(resolve);
			});
		}
	},
	async mounted() {
		if (this.autoFocus) {
			this.focus();
		}
		if(this.generalSettings.DefaultProductsF3Browse>0){
			this.view = await ViewService.getView(this.generalSettings.DefaultProductsF3Browse);
		}
	},
	components: {
		AppProductBrowse
	},
	i18nEx: {
		componentPrefix: "components.products"
	}
});
