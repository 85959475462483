import { ExtraFieldNamesConfig } from "@/models/dossier-configs/extra-field-names-config";
import { DossierConfigService } from "@/services/dossier-config-service";
import { i18n } from "@/setup/i18n-setup";
import { getSaleMapPropSelectGroup } from "../prop-select/sale-map-prop-group";
import { Field } from "./field";

export class SaleLayoutCol extends Field{
	public Width:number = 10;
	public Position:number = 10;
	public LineNumber:number = 0;
	public HeaderField:Field | null = null;

	constructor(data?: any) {
		super(data);
		if (!data) return;
		this.Width = data.Width;
		this.Position = data.Position;
		this.LineNumber = data.LineNumber;
		if (data.HeaderField){
			this.HeaderField = new Field(data.HeaderField);
		}
	}

	public getJSON() {
		let result = super.getJSON();
		return {
			...result,
			Width: this.Width,
			Position: this.Position,
			LineNumber: this.LineNumber,
			HeaderField: this.HeaderField ? this.HeaderField.getJSON() : null
		};
	}

	public getWidth():string{
		return `${this.Width}mm`;
	}

	public getNumericPosition(cols:SaleLayoutCol[]):number{
		let naturalPosition = 0;
		for (let col of cols){
			if (col == this){
				break;
			}
			naturalPosition += col.Width + col.Style.MarginLeft + col.Style.MarginRight;
		}
		return (this.Position - naturalPosition);
	}

	public getNumericHeaderPosition(cols:SaleLayoutCol[]):number{
		let naturalPosition = 0;
		for (let col of cols){
			if (col == this){
				break;
			}
			if (!col.HeaderField) {
				continue;
			}
			naturalPosition += col.Width + col.Style.MarginLeft + col.Style.MarginRight;
		}
		return (this.Position - naturalPosition);
	}

	public getPosition(cols:SaleLayoutCol[]):string{
		return `${this.getNumericPosition(cols)}mm`;
	}

	public getHeaderPosition(cols:SaleLayoutCol[]):string{
		return `${this.getNumericHeaderPosition(cols)}mm`;
	}

	public getText(lang:string):string {
		let text = this.Text.getTranslation(lang);
		if (!this.Prop) {
			return text;
		}
		if (this.Prop.startsWith("Description_")){
			for (let lang of DossierConfigService.getLanguages().LanguageNames) {
				if (this.Prop == `Description_${lang}`) {
					return i18n.t(`models.map.SaleRow.DescriptionLang`, {lang}).toString();
				}
			}
		}
		let propName = i18n.t(`models.map.SaleRow.${this.Prop}`).toString();
		if (this.Prop.startsWith("ExtraField")){
			let config = DossierConfigService.getExtraFieldNamesConfigs().ProductNames;
			let fieldName = this.Prop.split("ExtraField")[1] as keyof ExtraFieldNamesConfig;
			let foundName = config[fieldName] as string;
			if (foundName){
				propName = foundName;
			}
		}
		return text + propName;
	}

	public getHeadingText(lang:string):string {
		if (!this.HeaderField) return "";
		let text = this.HeaderField.Text.getTranslation(lang);
		if (!this.HeaderField.Prop) {
			return text;
		}
		let group = getSaleMapPropSelectGroup();
		let val = group.getPropSelectFromValue(this.HeaderField.Prop);
		if (!val){
			return text;
		}
		return text + val.text;
	}
}
