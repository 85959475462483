
import { IModel } from "@/utils/models/model-interface";
import { SummaryCol } from "@/utils/report-engine-settings/summary-col";
import AppColumnSelect from "@/components/models/ColumnSelect.vue";
import Vue from "vue";

export default Vue.extend({
	data(){
		return {
			col: new SummaryCol()
		};
	},
	props: {
		value: {
			type: Object as ()=>SummaryCol,
			required: true
		},
		model: {
			type: Object as ()=>IModel,
			required: true
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(){
				this.col = new SummaryCol(this.value.getJSON());
			}
		}
	},
	methods: {
		deselectCol(){
			this.col.Col = "";
		},
		close(){
			this.$emit("close");
		},
		confirm(){
			this.$emit("input", this.col);
			this.close();
		}
	},
	components: {
		AppColumnSelect
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
