export function isStringNullOrEmpty(text:string | null):boolean {
	if (text == null) {
		return true;
	}
	if (text == "") {
		return true;
	}
	return false;
}

export function isStringNullOrWhitespace(text:string | null):boolean {
	if (isStringNullOrEmpty(text)) {
		return true;
	}
	if (!text) {
		return true;
	}
	if (text.match(/^\s*$/)) {
		return true;
	}

	return false;
}

export function isStringEmail(text:string):boolean {
	return /(.+)@(.+){2,}\.(.+){2,}/.test(text.toLocaleLowerCase());
}

export function multiLineCut(text:string):string{
	if (text.indexOf("\n") != -1) {
		return `${text.split("\n")[0]}...`;
	}
	return text;
}

export function isPasswordStrongEnough(password:string):boolean {
	let expr = [/[a-z]/,/[A-Z]/,/[0-9]/,/.{6}.*/];
	for (let e of expr){
		if (!e.test(password)){
			return false;
		}
	}
	return true;
}

export function humanReadableByteCount(bytes:number):string{
	if (bytes < 1000) {
		return `${bytes}B`;
	}
	if (bytes < 1e6){
		return `${Math.round(bytes / 1e3)}KB`;
	}
	if (bytes < 1e9){
		return `${Math.round(bytes / 1e6)}MB`;
	}
	if (bytes < 1e12){
		return `${Math.round(bytes / 1e9)}GB`;
	}
	return `${Math.round(bytes / 1e12)}TB`;
}

export function safeString(input:string):string{
	return input.replace(/[\u00A0-\u9999<>\&]/gim, function(i) {
		return "&#"+i.charCodeAt(0)+";";
	});
}

export function stringReplaceCharAtIndex(str:string, index:number, replacement:string):string {
	return str.substring(0, index) + replacement + str.substring(index + replacement.length);
}

export function dateToSqlDateString(date:Date):string {
	return `${date.getFullYear()}/${date.getMonth()+1}/${date.getDate()}`;
}


export function getQuotedTexts(str:string, quoteType:string = "\""):string[] {
	let result:string[] = [];
	let quoteStartIndex = -1;
	let lastCharWasBackslash = false;
	for (let i = 0; i < str.length; i++){
		let char = str[i];
		if (char == quoteType) {
			if (lastCharWasBackslash) {
				lastCharWasBackslash = false;
				continue;
			}

			if (quoteStartIndex != -1) {
				let subStr = str.substring(quoteStartIndex, i);
				result.push(subStr);
				quoteStartIndex = -1;
			}else {
				quoteStartIndex = i;
			}
		}


		lastCharWasBackslash = false;
		if (char == "\\") {
			lastCharWasBackslash = true;
		}
	}

	return result;
}

// eslint-disable-next-line max-lines-per-function
export function splitByQuotesOrWhitespace(str:string, quoteType:string = "\""):string[] {
	let result:string[] = [];
	let quoteStartIndex = -1;
	let lastCharWasBackslash = false;
	let spacedStr = "";
	for (let i = 0; i < str.length; i++){
		let char = str[i];
		if (char == quoteType) {
			if (lastCharWasBackslash) {
				lastCharWasBackslash = false;
				continue;
			}
			if (quoteStartIndex != -1) {
				let subStr = str.substring(quoteStartIndex, i).replaceAll("\\\"", "\"");
				result.push(subStr);
				quoteStartIndex = -1;
			}else {
				if (spacedStr) {
					result.push(spacedStr);
					spacedStr = "";
				}
				quoteStartIndex = i+1;
			}
		}else if (char == " ") {
			if (quoteStartIndex == -1) {
				if (spacedStr) {
					result.push(spacedStr);
					spacedStr = "";
				}
			}
		}else if (quoteStartIndex == -1) {
			spacedStr += char;
		}
		lastCharWasBackslash = false;
		if (char == "\\") {
			lastCharWasBackslash = true;
		}
	}
	if (spacedStr){
		result.push(spacedStr);
	}

	return result;
}

export function stringToHash(string:string) {
	let hash = 0;

	if (string.length == 0) return hash;

	for (let i = 0; i < string.length; i++) {
		let char = string.charCodeAt(i);
		hash = ((hash << 5) - hash) + char;
		hash = hash & hash;
	}
	return hash;
}

export function removeDuplicatesFromStringList(list:string[]){
	for (let i = 0; i < list.length; i++) {
		let operator = list[i];
		for (let c = list.length-1; c > i; c--){
			if (operator == list[c]){
				list.splice(c, 1);
			}
		}
	}
}