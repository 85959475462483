import { ServerConfig } from "@/config/config";
import { Job } from "@/models/job";
import { Contact, Product, ProductExtraFields, ProductPriceCategorySalesDates, Vat } from "@/models/base";
import { ProductCompositionRow } from "@/models/base";
import { ProductImage } from "@/models/base";
import { Axios } from "@/utils/axios";
import { advancedSearch } from "@/utils/browse/browse";
import { IQueryParams } from "@/utils/query-params";
import { HttpError } from "@/utils/services/http-error";
import { LastOpenedItem } from "@/utils/last-opened";
import { View } from "@/models/view";
import { FetchedTotal } from "@/utils/views/fetched-total";
import { ProductAwsDocument } from "@/models/base/prolduct-aws-document";
import { IGetResponse } from "@/utils/get-response";
import { AutoBarcode } from "@/models/dossier-configs/auto-barcode";
import { IExtraFieldQuickEditViaMenuDataResult } from "@/utils/models/extra-fields";
import { VatService } from "./vat-service";
import { UserConfigService } from "./user-config-service";
import { ProductBatchEditSellPricesBodyPrices } from "@/utils/products/product-batch-edit-sell-prices-body-prices";
import {ProductPriceRule} from "@/models/base/product-price-rule";

export enum ProductCSVImportExistingProductHandling {
	ERROR = "error",
	UPDATE = "update",
	IGNORE = "ignore"
}

export enum ProductCSVImportNewProductsHandling{
	CREATE = "create",
	IGNORE = "ignore"
}

export enum ProductCSVImportProductMatchingMethod{
	SKU = "sku",
	SUPPLIER_CODE = "supplier-code",
	MANUFACTURER_CODE = "manufacturer-code"
}

export class ProductServiceClass {
	selectedProducts = [] as Product[];

	lastOpenedItem = new LastOpenedItem("product", (product:Product)=>{
		product.ID = 0;
		product.Sku = "";
		product.ExtraFieldsID = 0;
		return product;
	});
	private readonly url = `${ServerConfig.host}/product`;
	private readonly compositionRowUrl = `${ServerConfig.host}/product-composition-row`;

	async getProducts(query: IQueryParams): Promise<IGetResponse<Product>> {
		let result = await advancedSearch(query, this.url + "/view");
		let records = result.data.records as number;
		let contacts = result.data.data.map((c: any) => new Product(c));
		return {records, data: contacts};
	}

	async getTotals(view:View):Promise<FetchedTotal[]>{
		let result = await Axios.get(`${this.url}/view/${view.ID}/totals`);
		return (result.data || []).map((d:any)=>new FetchedTotal(d));
	}

	async getProduct(id: number): Promise<Product> {
		let result = await Axios.get(`${this.url}/${id}`, {});
		return new Product(result.data);
	}

	async getProductsForGroup(id:number):Promise<Product[]>{
		let result = await Axios.get(`${this.url}/by-group-id/${id}`, {});
		return result.data.map((p:any)=>new Product(p));
	}

	async getProductCount():Promise<Number>{
		let result = await Axios.get(`${this.url}`);
		return result.data.records;
	}

	async delProducts(products: Product[]): Promise<void> {
		await Axios.delete(this.url, {
			data: products.map(p => p.ID)
		});
	}

	private async updateProductProcessImages(product:Product, newProduct:Product){
		let images = await this.uploadImages(newProduct, product.toUploadImages.map(t=>t.file));
		for (let index in product.toUploadImages){
			let img = product.toUploadImages[index];
			if (img.setAsCover) {
				await this.setCoverImage(newProduct, images[index]);
			}
		}

		for (let image of product.toRemoveImages) {
			await this.deleteImage(image);
		}
	}

	async postProduct(product: Product): Promise<Product> {
		let result = await Axios.post(`${this.url}`, product.getJSON());
		let p = new Product(result.data);
		if(product.UnprocessedFiles.length){
			for (let file of product.UnprocessedFiles){
				await this.uploadDocument(p,file);
			}
		}
		await this.updateProductProcessImages(product, p);
		return this.getProduct(p.ID);
	}

	async setCoverImage(product:Product, image:ProductImage) {
		await Axios.post(`${this.url}/${product.ID}/set-cover-image/${image.ID}`);
	}

	async putProduct(product: Product): Promise<Product> {
		let result = await Axios.put(`${this.url}`, product.getJSON());
		for (let file of product.UnprocessedFiles){
			await this.uploadDocument(product,file);
		}
		for (let file of product.toDeleteFiles){
			await this.deleteDocument(product, file);
		}
		let p = new Product(result.data);
		await this.updateProductProcessImages(product, p);
		return this.getProduct(p.ID);
	}

	async search(sku: string, includeDisabled:boolean = false): Promise<Product[]> {
		let result = await Axios.get(`${this.url}/search?input=${sku}&includeDisabled=${includeDisabled}`);
		return result.data.map((p: any) => new Product(p));
	}

	async existsBySku(sku: string, ): Promise<Boolean> {
		try{
			await Axios.get(`${this.url}/by-barcode-or-sku?skuOrBarCode=${sku}`);
			return true;
		}catch(err){
			let e = err as HttpError;
			if (e.status == 404){
				e.dontShow = true;
				return false;
			}
			throw e;
		}
	}
	async findBySku(sku: string): Promise<Product> {
		let result = await Axios.get(`${this.url}/by-barcode-or-sku?skuOrBarCode=${sku}`);
		return new Product(result.data);
	}

	async existsBySkuIncludeNonActive(sku: string): Promise<Boolean> {
		try{
			await Axios.get(`${this.url}/by-barcode-or-sku-include-non-active?skuOrBarCode=${sku}`);
			return true;
		}catch(err){
			let e = err as HttpError;
			if (e.status == 404){
				e.dontShow = true;
				return false;
			}
			throw e;
		}
	}

	async findBySkuMathcAfter0(sku:string):Promise<Product>{
		let result = await Axios.get(`${this.url}/by-barcode-or-sku-match-after-0?skuOrBarCode=${sku}`);
		return new Product(result.data);
	}

	async postProductCompositionRows(rows: ProductCompositionRow[]): Promise<ProductCompositionRow> {
		let result = await Axios.post(`${this.compositionRowUrl}`, rows.map(r => r.getJSON()));
		return result.data.map((r: any) => new ProductCompositionRow(r));
	}
	async putProductCompositionRows(rows: ProductCompositionRow[]): Promise<ProductCompositionRow> {
		let result = await Axios.put(`${this.compositionRowUrl}`, rows.map(r => r.getJSON()));
		return result.data.map((r: any) => new ProductCompositionRow(r));
	}
	async deleteProductCompositionRows(rows: ProductCompositionRow[]): Promise<void> {
		await Axios.delete(`${this.compositionRowUrl}`, {
			data: rows.map(r => r.ID)
		});
	}

	async uploadImages(product:Product, images:File[]):Promise<ProductImage[]> {
		if (images.length == 0) return [];
		let data = new FormData();
		data.append("image-count", images.length + "");
		for (let i in images) {
			let img = images[i];

			data.append(`img-${parseInt(i) + 1}`, img, img.name);
		}
		let result = await Axios.post(`${this.url}/${product.ID}/upload-images`, data);
		return result.data.map((r:any) => new ProductImage(r));
	}
	async deleteImage(image:ProductImage):Promise<void> {
		await Axios.delete(`${this.url}/image/${image.ID}`);
	}

	async importProductsCSV(file:File,
		existingProductHandling:ProductCSVImportExistingProductHandling,
		newProductsHandling:ProductCSVImportNewProductsHandling,
		productMatchingMethod:ProductCSVImportProductMatchingMethod,
		useCommaSeperator:boolean):Promise<Job> {
		let data = new FormData();
		data.append("file", file, file.name);
		data.append("existing-product-handling", existingProductHandling);
		data.append("use-comma-as-decimal-seperator", useCommaSeperator ? "true" : "false");
		data.append("new-product-handling", newProductsHandling);
		data.append("product-matching-method", productMatchingMethod);
		let result = await Axios.post(`${this.url}/import-csv`, data);
		return new Job(result.data);
	}

	public async uploadDocument(product:Product, file:File):Promise<ProductAwsDocument>{
		let data = new FormData();
		data.append("file", file, file.name);
		let result = await Axios.post(`${this.url}/${product.ID}/document`, data);
		return new ProductAwsDocument(result);
	}

	public async deleteDocument(product:Product, document:ProductAwsDocument):Promise<void>{
		await Axios.delete(`${this.url}/${product.ID}/document/${document.ID}`);
	}

	// returns list of affected products
	public async markForLabelPrinter(products:Product[]):Promise<number>{
		let ids = products.map(p=>p.ID);
		let result = await Axios.post(`${this.url}/mark-for-label-print`, ids);
		products.forEach(p=>p.MarkedToPrintLabel = true);
		return result.data.ProductsChanged;
	}

	// returns list of affected products
	public async unmarkForLabelPrinter(products:Product[]):Promise<number>{
		let ids = products.map(p=>p.ID);
		let result = await Axios.post(`${this.url}/unmark-for-label-print`, ids);
		products.forEach(p=>p.MarkedToPrintLabel = false);
		return result.data.ProductsChanged;
	}

	public async importFromKramp():Promise<Job>{
		let result = await Axios.post(`${this.url}/import-from-kramp`);
		return new Job(result.data);
	}

	public async getProductsByIds(ids:number[]):Promise<Product[]>{
		let result = await Axios.get(`${this.url}/by-ids`, {params: {ids}});
		return (result.data as any[]).map(c=> new Product(c));
	}

	public async batchEditSellPrices(products:Product[],dates:ProductPriceCategorySalesDates, OverwriteAllValues: boolean, prices:ProductBatchEditSellPricesBodyPrices[]){
		let ids = products.map(p=>p.ID);
		await Axios.post(`${this.url}/batch-set-prices`, {ProductIDs: ids,SaleDates: dates,OverwriteAllValues,Prices: prices});
	}

	public async updateExtraFields(contact:Product, extraFields:ProductExtraFields):Promise<ProductExtraFields>{
		let result = await Axios.put(`${this.url}/extra-fields/${contact.ExtraFieldsID}`, extraFields.getJSON());
		return new ProductExtraFields(result.data);
	}

	async incrementAutoBarcode():Promise<AutoBarcode>{
		let result = await Axios.post(`${this.url}/increment-auto-barcode`);
		return new AutoBarcode(result.data);
	}

	async getProductsWithLessStockThenMinStockBySupplier(supplier:Contact, groupIds:number[]):Promise<Product[]>{
		let result = await Axios.get(`${this.url}/with-less-stock-then-min-stock-by-supplier/${supplier.ID}`, {params: {
			groupIds: JSON.stringify(groupIds)
		}});
		return ((result.data || []) as any[]).map(p=>new Product(p));
	}

	public async batchEditExtraField(products:Product[], field:string, value:IExtraFieldQuickEditViaMenuDataResult) {
		let body = {
			ExtraFieldIDs: products.map(s=>s.ExtraFieldsID),
			Field: field,
			NumValue: value.numValue,
			TextValue: value.textValue,
			BoolValue: value.boolValue,
			TimeValue: value.timeValue
		};
		await Axios.post(`${this.url}/batch-edit-extra-field`, body);
	}

	public async getProductsBySkus(skus:string[]):Promise<Product[]>{
		let result = await Axios.get(`${this.url}/by-skus`, {params: {
			skus: [...skus]
		}});
		return result.data.map((p:any)=>new Product(p));
	}

	public async getProductBySku(sku:string):Promise<Product | null>{
		let result = await Axios.get(`${this.url}/by-sku`, {params: {sku}});
		return new Product(result.data);
	}

	public async generateRandomBarCode():Promise<string>{
		let result = await Axios.get(`${this.url}/generate-random-barcode`);
		return result.data;
	}

	public getDefaultNewProductVat():Vat {
		try{
			return VatService.getVat(UserConfigService.getSaleSettings().DefaultNewProductVatID);
		}catch(err){
			return VatService.getDefaultVat();
		}
	}

	public async setDefaultExtraFieldValues():Promise<void>{
		await Axios.post(`${this.url}/set-default-extra-field-values`);
	}

	public async addPriceRule(product:Product, rule:ProductPriceRule):Promise<ProductPriceRule>{
		let result = await Axios.post(`${this.url}/${product.ID}/price-rule`, rule.getJSON());
		rule = new ProductPriceRule(result.data);
		product.PriceRules.push(rule);
		return rule;
	}

	public async updatePriceRule(product:Product, rule:ProductPriceRule):Promise<ProductPriceRule>{
		let result = await Axios.put(`${this.url}/${product.ID}/price-rule/${rule.ID}`, rule.getJSON());
		rule = new ProductPriceRule(result.data);
		let i = product.PriceRules.findIndex(r=>r.ID == rule.ID);
		if (i == -1) {
			return rule;
		}
		product.PriceRules[i] = rule;
		return rule;
	}

	public async updateProductCompositions(id:number){
		await Axios.post(`${this.url}/${id}/update-referenced-compositions`);
	}

};

export const ProductService = new ProductServiceClass();