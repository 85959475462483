
export enum BorderStyle {
	NONE="none",
	SOLID="solid",
	HIDDEN="hidden",
	DOTTED="dotted",
	DASHED="dashed",
}

export class StyledProperty {
	public MarginLeft: number = 0;
	public MarginRight: number = 0;
	public MarginBottom: number = 0;
	public MarginTop: number = 0;
	public PaddingLeft: number = 0;
	public PaddingRight: number = 0;
	public PaddingTop: number = 0;
	public PaddingBottom: number = 0;
	public BorderStyle:BorderStyle = BorderStyle.NONE;
	public BorderColor:string = "black";
	public BorderWidth:number = 0;
	public BorderTopWidth:number = 0;
	public BorderRightWidth:number = 0;
	public BorderBottomWidth:number = 0;
	public BorderLeftWidth:number = 0;
	public BorderRadiusTopLeft:number = 0;
	public BorderRadiusTopRight:number = 0;
	public BorderRadiusBottomLeft:number = 0;
	public BorderRadiusBottomRight:number = 0;
	public BackgroundColor: string = "transparent";

	public constructor(data?:any) {
		if (!data) return;
		this.setValues(data);
	}

	public setValues(data:any){
		this.MarginLeft = data.MarginLeft || 0;
		this.MarginRight = data.MarginRight || 0;
		this.MarginTop = data.MarginTop || 0;
		this.MarginBottom = data.MarginBottom || 0;
		this.PaddingLeft = data.PaddingLeft || 0;
		this.PaddingRight = data.PaddingRight || 0;
		this.PaddingTop = data.PaddingTop || 0;
		this.PaddingBottom = data.PaddingBottom || 0;
		this.BorderStyle = data.BorderStyle || BorderStyle.NONE;
		this.BorderColor = data.BorderColor;
		this.BorderTopWidth = data.BorderTopWidth;
		this.BorderRightWidth = data.BorderRightWidth;
		this.BorderBottomWidth = data.BorderBottomWidth;
		this.BorderLeftWidth = data.BorderLeftWidth;
		this.BorderWidth = data.BorderWidth;
		this.BorderRadiusTopLeft = data.BorderRadiusTopLeft;
		this.BorderRadiusTopRight = data.BorderRadiusTopRight;
		this.BorderRadiusBottomLeft = data.BorderRadiusBottomLeft;
		this.BorderRadiusBottomRight = data.BorderRadiusBottomRight;
		this.BackgroundColor = data.BackgroundColor;
	}

	public getStyle():any {
		return {
			backgroundColor: this.BackgroundColor,
			marginBottom: this.MarginBottom + "mm",
			marginTop: this.MarginTop + "mm",
			marginLeft: this.MarginLeft + "mm",
			marginRight: this.MarginRight + "mm",
			paddingBottom: this.PaddingBottom + "mm",
			paddingTop: this.PaddingTop + "mm",
			paddingLeft: this.PaddingLeft + "mm",
			paddingRight: this.PaddingRight + "mm",
			borderStyle: this.BorderStyle,
			borderColor: this.BorderColor,
			/*borderWidth: this.BorderWidth != 0 ? `${this.BorderWidth}mm` : "0mm",*/
			borderTopWidth: `${this.BorderTopWidth}mm`,
			borderRightWidth: `${this.BorderRightWidth}mm`,
			borderBottomWidth: `${this.BorderBottomWidth}mm`,
			borderLeftWidth: `${this.BorderLeftWidth}mm`,
			borderTopLeftRadius: `${this.BorderRadiusTopLeft}mm`,
			borderTopRightRadius: `${this.BorderRadiusTopRight}mm`,
			borderBottomLeftRadius: `${this.BorderRadiusBottomLeft}mm`,
			borderBottomRightRadius: `${this.BorderRadiusTopRight}mm`,
		};
	}

	public getJSON():any{
		return {
			MarginLeft: this.MarginLeft,
			MarginRight: this.MarginRight,
			MarginTop: this.MarginTop,
			MarginBottom: this.MarginBottom,
			PaddingBottom: this.PaddingBottom,
			PaddingTop: this.PaddingTop,
			PaddingLeft: this.PaddingLeft,
			PaddingRight: this.PaddingRight,
			BorderStyle: this.BorderStyle,
			BorderColor: this.BorderColor,
			BorderWidth: this.BorderWidth,
			BorderTopWidth: this.BorderTopWidth,
			BorderRightWidth: this.BorderRightWidth,
			BorderBottomWidth: this.BorderBottomWidth,
			BorderLeftWidth: this.BorderLeftWidth,
			BorderRadiusTopLeft: this.BorderRadiusTopLeft,
			BorderRadiusTopRight: this.BorderRadiusTopRight,
			BorderRadiusBottomLeft: this.BorderRadiusBottomLeft,
			BorderRadiusBottomRight: this.BorderRadiusBottomRight,
			BackgroundColor: this.BackgroundColor
		};
	}

	public getPaddingHeight():number {
		return this.PaddingBottom + this.PaddingTop;
	}

	public getBorderHeight():number {
		if (this.BorderStyle == "none"){
			return 0;
		}
		let borderTopWidth = this.BorderWidth;
		if (this.BorderTopWidth != 0 && this.BorderWidth != this.BorderTopWidth) {
			borderTopWidth = this.BorderTopWidth;
		}
		let borderBottomWidth = this.BorderWidth;
		if (this.BorderBottomWidth != 0 && this.BorderWidth != this.BorderBottomWidth) {
			borderBottomWidth = this.BorderBottomWidth;
		}
		return borderTopWidth + borderBottomWidth;
	}

	public setAllBorderWidths(width:number) {
		this.BorderWidth = width;
		this.BorderTopWidth = width;
		this.BorderBottomWidth = width;
		this.BorderLeftWidth = width;
		this.BorderRightWidth = width;
	}

	public hasMultipleBorderWidths():boolean {
		if (this.BorderWidth != this.BorderTopWidth) {
			return true;
		}
		if (this.BorderWidth != this.BorderRightWidth){
			return true;
		}
		if (this.BorderWidth != this.BorderBottomWidth){
			return true;
		}
		if (this.BorderWidth != this.BorderLeftWidth){
			return true;
		}
		return false;
	}
}