import { ModalService } from "@/services/modal-service";
import { ISelectItem } from "winfakt-vue-components";
import * as services from "@/services";
import { BrowseStateBase } from "./browse-state-base";

export enum BrowseExtraButtonLocation {
	MENU = 0,
	REPORTS = 1
}

export class BrowseExtraButton {
	public ID:number = 0;
	public BrowseID:number = 0;
	public Location:BrowseExtraButtonLocation = BrowseExtraButtonLocation.MENU;
	public Label:string = "";
	public Icon:string = "";
	public Code:string = "";
	public OpenInModal:boolean = false;
	public PaddedModal:boolean = false;
	public Width:number = 0.0;
	public Height:number = 0.0;

	public constructor(data:any){
		this.ID = data.ID;
		this.BrowseID = data.BrowseID;
		this.Location = data.Location;
		this.Label = data.Label;
		this.Icon = data.Icon;
		this.Code = data.Code;
		this.OpenInModal = data.OpenInModal;
		this.PaddedModal = data.PaddedModal;
		this.Width = data.Width || 0;
		this.Height = data.Height || 0;
	}

	public getJSON(){
		return {
			ID: this.ID,
			BrowseID: this.BrowseID,
			Location: this.Location,
			Label: this.Label,
			Icon: this.Icon,
			Code: this.Code,
			OpenInModal: this.OpenInModal,
			PaddedModal: this.PaddedModal,
			Width: this.Width,
			Height: this.Height
		};
	}


	toSelectItem(state:BrowseStateBase):ISelectItem<Function>{
		let AsyncFunctionConstructor:any = new Function("return (async function(){}).constructor;")();
		let func = new AsyncFunctionConstructor("services", "browseState", this.Code) as Function;
		let value = async ()=>{
			let url = await func(services);
			window.open(url);
		};
		if (this.OpenInModal) {
			value = async ()=>{
				let url = await func(services, state);
				if (!url) return;
				ModalService.openIframeModal(url, this.Label, {padded: this.PaddedModal, width: this.Width, height: this.Height});
			};
		}
		return {
			text: this.Label,
			icon: this.Icon,
			value
		};
	}
}