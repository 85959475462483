import { ServerConfig } from "@/config/config";
import { FrequencyType, FrequencyDocument } from "@/models/base";
import { Job } from "@/models/job";
import { View } from "@/models/view";
import { Axios } from "@/utils/axios";
import { advancedSearch} from "@/utils/browse/browse";
import { IGetResponse } from "@/utils/get-response";
import { IQueryParams } from "@/utils/query-params";
import { FetchedTotal } from "@/utils/views/fetched-total";

export class FrequencyDocumentServiceClass {
	get url():string {
		return `${ServerConfig.host}/frequency-document`;
	}

	async getFrequencyDocuments(query:IQueryParams):Promise<IGetResponse<FrequencyDocument>> {
		let result = await advancedSearch(query, this.url + "/view");
		let records = result.data.records as number;
		let docs = result.data.data.map((c: any) => new FrequencyDocument(c));
		return {data: docs, records};
	}

	async getTotals(view:View):Promise<FetchedTotal[]>{
		let result = await Axios.get(`${this.url}/view/${view.ID}/totals`);
		return (result.data || []).map((d:any)=>new FetchedTotal(d));
	}

	async getDocument(id:number):Promise<FrequencyDocument> {
		let result = await Axios.get(`${this.url}/${id}`);
		return new FrequencyDocument(result.data);
	}

	async deleteDocs(docs:FrequencyDocument[]) {
		await Axios.delete(`${this.url}`, {data: docs.map(d => d.ID)});
	}

	async postDoc(doc:FrequencyDocument):Promise<FrequencyDocument> {
		let result = await Axios.post(`${this.url}`, doc.getJSON());
		return new FrequencyDocument(result.data);
	}

	async putDoc(doc:FrequencyDocument):Promise<FrequencyDocument> {
		let result = await Axios.put(`${this.url}`, doc.getJSON());
		return new FrequencyDocument(result.data);
	}

	async createManualFrequencyDocuments():Promise<Job> {
		let result = await Axios.post(`${this.url}/create-manual-frequency-documents`, {});
		return new Job(result.data);
	}

	async checkExecutionDates(startDate:Date, endDate:Date | null, frequencyType:FrequencyType, frequencyMoment:string, interval:number, ignoreStartDate:boolean):Promise<Date[]> {
		let result = await Axios.post(`${this.url}/check-execution-dates`, {
			StartDate: startDate.toJSON(),
			EndDate: endDate ? endDate.toJSON() : null,
			FrequencyType: frequencyType,
			FrequencyMoment: frequencyMoment,
			Interval: interval,
			IgnoreStartDate: ignoreStartDate
		});
		return result.data.map((r:number) => new Date(r));
	}

	async stopDocument(frequencyDocument:FrequencyDocument):Promise<FrequencyDocument>{
		let result = await Axios.post(`${this.url}/${frequencyDocument.ID}/stop`);
		return new FrequencyDocument(result.data);
	}
};

export const FrequencyDocumentService = new FrequencyDocumentServiceClass();