import { TranslateResult } from "vue-i18n";
import { IModelPropertyClickAction } from "./model-field";
import { i18n } from "@/setup/i18n-setup";
import { ModalService } from "@/services";

export const imageClickAction:IModelPropertyClickAction = {
	getName():TranslateResult{
		return i18n.t("common.open-image");
	},
	handler(target:any, prop:PropertyKey):void{
		let parts = (prop as string).split(".");
		for (let part of parts) {
			if (!target) return;
			target = (target as any)[part] as any;
		}
		console.log(target, prop);
		console.log(target);
		ModalService.openImageUrl(target);
	}
};

export const extraFieldEditClickAction:IModelPropertyClickAction = {
	getName():TranslateResult{
		return i18n.t("common.edit-extra-field");
	},
	handler(target:any, prop:PropertyKey):string{
		return `edit-extra-field/${prop as string}`;
	},
	canBeUsed: (browse, path)=>{
		console.log(path);
		return path.startsWith("ExtraFields.");
	}
};
