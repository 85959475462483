
import { ContactPriceRule } from "@/models/base/contact-price-rule";
import AppFilterItem from "./ContactEditPriceRuleFilterItem.vue";
import Vue from "vue";
import { Condition, Operator } from "@/utils/condition/condition";
import AppSellCategorySelect from "@/components/SellCategorySelect.vue";
import {Contact} from "@/models/base";
import { Browse } from "@/models/browse";

export default Vue.extend({
	data(){
		return {
			rule: new ContactPriceRule(),
			openedCondition: null as null | Condition
		};
	},
	props: {
		value: {
			type: Object as ()=> ContactPriceRule,
			required: true
		},
		contact: {
			type: Object as ()=>Contact,
			required: true
		},
		browse: {
			type: Object as ()=>Browse
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(value:ContactPriceRule){
				this.rule = new ContactPriceRule(this.value.getJSON());
				if(this.rule.PriceCategory == 0){
					this.rule.PriceCategory = this.contact.SellPriceCategory;
				}
			}
		}
	},
	methods: {
		close(){
			this.$emit("close");
		},
		save(){
			this.$emit("input", new ContactPriceRule(this.rule.getJSON()));
			this.close();
		},
		addNewFilter(){
			let condition = new Condition();
			condition.Prop = "ProductSku",
			condition.Operator = Operator.EQ;
			condition.Value = "1";
			this.rule.Filters.push(condition);
			this.openedCondition = condition;
		},
		deleteItem(i:number){
			this.rule.Filters.splice(i, 1);
		},
		editItem(item:Condition){
			if (this.openedCondition == item) {
				this.openedCondition = null;
				return;
			}
			this.openedCondition = item;
		}
	},
	components: {
		AppFilterItem,
		AppSellCategorySelect
	},
	i18nEx: {
		componentPrefix: "components.contacts"
	}
});
