import { getFileUrl } from "@/utils/url";
import { computed, displayClasses, field, filterOnly, filterValueInput, hidden, onClick, print } from "@/utils/models/decorator-field";
import { model } from "@/utils/models/decorator-model";
import { printImage } from "@/utils/pretty-print";
import { IModelPropertyClickAction } from "@/utils/models/model-field";
import { TranslateResult } from "vue-i18n";
import { i18n } from "@/setup/i18n-setup";
import { ModalService, ProductService } from "@/services";

const productImageClickAction:IModelPropertyClickAction = {
	getName():TranslateResult{
		return i18n.t("common.open-image");
	},
	handler(target:any, prop:PropertyKey):void{
		let parts = (prop as string).split(".");
		parts.pop();
		for (let part of parts) {
			if (!target) return;
			target = (target as any)[part] as any;
		}
		let t = target as ProductImage;
		let list = [t.getFileUrl()];
		ModalService.openImageUrlList(list);
		ProductService.getProduct(t.ProductID).then((product)=>{
			product.Images.forEach(i=>{
				let url = i.getFileUrl();
				if (list.indexOf(url) != -1) return;
				list.push(url);
			});
		});
	}
};

@model("ProductImage")
export default class ProductImage{
	@field("number")
	public ID:number = 0;

	@field("number")
	@filterValueInput("Product")
	@filterOnly()
	public ProductID:number = 0;

	@field("string")
	@hidden()
	public FullURL:string = "";

	@field("string")
	@computed()
	@print(printImage)
	@displayClasses(["center"])
	@onClick(productImageClickAction)
	public get Image():string {
		return this.getFileUrl();
	}

	setAsCover:boolean = false;

	public constructor(data?: any) {
		if (!data) {
			return;
		}
		this.ID = data.ID;
		this.ProductID = data.ProductID;
		this.FullURL = data.FullURL;
	}

	getJSON() {
		return {
			ID: this.ID,
			ProductID: this.ProductID,
			FullURL: this.FullURL
		};
	}

	getFileUrl():string{
		if (!this.FullURL) {
			return "";
		}
		return getFileUrl(this.FullURL);
	}
}
