import { ExtraFieldBaseConfig } from "./extra-field-base-config";

export class ExtraFieldNumberConfig extends ExtraFieldBaseConfig{
	Decimals:number = 2;
	DefaultValue:number = 0.0;

	public constructor(data?:any){
		super(data);
		if (!data) return;
		this.Decimals = data.Decimals;
		this.DefaultValue = data.DefaultValue;
	}

	public getJSON(){
		return {
			...(super.getJSON()),
			Decimals: this.Decimals,
			Defaultvalue: this.DefaultValue
		};
	}
}