export function roundTo8Decimals(num: number): number {
	return roundToDecimals(num, 8);
}

export function roundToDecimals(num:number, decimals:number):number{
	return parseFloat(num.toFixed(decimals));
}

export function roundPriceValues(num:number):number{
	return roundToDecimals(num, 2);
}

export function isBetween(num:number, num1:number, num2:number):boolean {
	if (num > num1 && num < num2) {
		return true;
	}
	return false;
}

export function isBetweenInclusive(num:number, num1:number, num2:number):boolean {
	if (num >= num1 && num <= num2) {
		return true;
	}
	return false;
}

export function isAboutEqual(num1:number, num2:number, maxDiff:number):boolean {
	return Math.abs(Math.abs(num1) - Math.abs(num2)) < maxDiff;
}

export function isAboutEqualPrice(num1:number, num2:number):boolean {
	return isAboutEqual(num1, num2, 0.01);
}

export function boolOrNumberToNumber(val:boolean | number):number {
	if (val == false) {
		return 0;
	}
	if (val == true){
		return 1;
	}
	return val as number;
}

export function pad(val:number, digits:number):string {
	let v = val.toString();
	for (let i = v.length; i<digits;i++){
		v = "0"+v;
	}
	return v;
}