import {v4 as uuid} from "uuid";
import { tokenStore } from "../token-store";
import { SocketServiceBase } from "./socket-service-base";
import io from "socket.io-client";
const patch = require("socketio-wildcard")(io.Manager);

let id = uuid();

export class SocketMainThread extends SocketServiceBase {
	private accessToken:string = ""
	private socket:SocketIOClient.Socket | null = null;

	async emit(event: string, ...data: any): Promise<any> {
		return new Promise((resolve)=>{
			if (!this.socket) return;
			this.socket.emit(event, ...data, resolve);
		});
	}

	private getConnectOptions():SocketIOClient.ConnectOpts {
		return {
			transports: ["websocket"],
			upgrade: false,
			path: this.path,
			reconnection: true,
			autoConnect: false,
			reconnectionAttempts: Infinity,
			reconnectionDelay: 1000,
			reconnectionDelayMax: 5000,
			query: { token: this.accessToken, id: this.getId() }
		};
	}

	async connect(): Promise<void> {
		if (this.socket) return;
		let options = this.getConnectOptions();
		return new Promise((resolve)=>{
			this.socket = io(this.host, options);
			patch(this.socket);

			this.socket.on("disconnect", ()=>{
				this.setIsConnected(false);
				this.emit("disconnect");
			});

			this.socket.on("reconnect_attempt", ()=>{
				this.setIsConnected(false);
				(this.socket as any).query.token = tokenStore.getSelectedToken();
				(this.socket as any).query.id = this.getId();
			});

			this.socket.on("*", (args:any)=>{
				args.data = [...args.data];
				let event = args.data.shift();
				this.triggerEvent(event, ...(args.data as any[]));
				if (event == "/init-error"){
					this.disconnect();
					this.connect();
				}
			});

			this.on("/ready",()=>{
				this.setIsConnected(true);
				resolve();
			});

			this.socket.connect();
		});
	}


	async disconnect(): Promise<void> {
		if (!this.socket) return;
		this.socket.disconnect();
		this.socket = null;
	}

	getId(): string {
		return id;
	}

	setAccessToken(token: string): void {
		this.accessToken = token;
	}

}