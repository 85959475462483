import { ServerConfig } from "@/config/config";
import { Sale, SaleRow } from "@/models/base";
import { View } from "@/models/view";
import { Axios } from "@/utils/axios";
import { advancedSearch, advancedSearchStaticView } from "@/utils/browse/browse";
import { IGetResponse } from "@/utils/get-response";
import { HumanFilter } from "@/utils/human-filter";
import { IQueryParams } from "@/utils/query-params";
import { FetchedTotal } from "@/utils/views/fetched-total";
export class SaleRowServiceClass{
	private isForPurchaseDocuments:boolean = false;
	public constructor(isPurchaseRow:boolean) {
		this.isForPurchaseDocuments = isPurchaseRow;
	}

	get url():string{
		if (this.isForPurchaseDocuments) {
			return ServerConfig.host + "/purchase-document-row";
		}
		return ServerConfig.host + "/sale-row";
	}

	async getSalesRows(query: IQueryParams, extraFilters:HumanFilter[] = [], bookyear: string = ""): Promise<IGetResponse<SaleRow>> {
		let result = await advancedSearch(query, this.url + "/view", extraFilters,{Bookyear: bookyear});
		let records = result.data.records as number;
		let sales = result.data.data.map((c: any) => new SaleRow(c));
		return {data: sales, records};
	}

	async markAsDelivered(rows:SaleRow[], amount:number = 0, date: Date = new Date()):Promise<number>{
		let body = rows.map(r=>{
			return {SaleRowID: r.ID, Amount: amount || r.Backorder, Date: date};
		});
		let result = await Axios.post(`${this.url}/mark-as-delivered`, body);
		return result.data.ChangedRows;
	}

	async markAsUndeliverable(rows:SaleRow[]):Promise<SaleRow[]>{
		let result = await Axios.post(`${this.url}/mark-as-undeliverable`, rows.map(r=>r.ID));
		return (result.data || []).map((r:any)=>new SaleRow(r));
	}
	async markAsDeliverable(rows:SaleRow[]):Promise<SaleRow[]>{
		let result = await Axios.post(`${this.url}/mark-as-deliverable`, rows.map(r=>r.ID));
		return (result.data || []).map((r:any)=>new SaleRow(r));
	}

	async getTotals(view:View):Promise<FetchedTotal[]>{
		let result = await Axios.get(`${this.url}/view/${view.ID}/totals`);
		return (result.data || []).map((d:any)=>new FetchedTotal(d));
	}

	async getSaleRowsStaticView(view:View, query: IQueryParams, bookyear: string = "", extraFilters:HumanFilter[] = []):Promise<IGetResponse<Sale>> {
		let result = await advancedSearchStaticView(view, query, this.url + "/raw-view", extraFilters, {Bookyear: bookyear});
		let records = result.data.records as number;
		let sales = result.data.data.map((c: any) => new SaleRow(c));
		return {data: sales, records};
	}


	async createAfleveringsbonnenOfDeliveredGoods(rowIds:number[]):Promise<number>{
		let result = await Axios.post(`${this.url}/create-afleveringsbonnen-of-delivered-goods`, rowIds);
		return result.data.CreatedDocumentsCount;
	}

	async afleveringsbonnenOfDeliveredGoodsCount():Promise<number>{
		let result = await Axios.get(`${this.url}/afleveringsbonnen-of-delivered-goods-count`);
		return result.data.Count;
	}
};

export const SaleRowService = new SaleRowServiceClass(false);
export const PurchaseDocumentRowService = new SaleRowServiceClass(true);