import { formatDistanceToNow as formatDistanceToNowFromDateFns } from "date-fns";
import Nl from "date-fns/locale/nl";
import Fr from "date-fns/locale/fr";
import { i18n } from "@/setup/i18n-setup";

export function isSameDate(date1:Date, date2:Date):boolean {
	if (date1.getFullYear() != date2.getFullYear()) {
		return false;
	}
	if (date1.getMonth() != date2.getMonth()) {
		return false;
	}
	if (date1.getDate() != date2.getDate()) {
		return false;
	}
	return true;
}

export function isSmallerDate(date1:Date, date2:Date):boolean {
	if (date1.getFullYear() < date2.getFullYear()) {
		return true;
	}
	if (date1.getMonth() < date2.getMonth()){
		return true;
	}
	if (date1.getDate() < date2.getDate()) {
		return true;
	}
	return false;
}

export function isGreaterDate(date1:Date, date2:Date):boolean {
	if (date1.getFullYear() > date2.getFullYear()) {
		return true;
	}
	if (date1.getMonth() > date2.getMonth()){
		return true;
	}
	if (date1.getDate() > date2.getDate()) {
		return true;
	}
	return false;
}

export function isSamllerOrEqualDate(date1:Date, date2:Date):boolean {
	return isSmallerDate(date1, date2) || isSameDate(date1, date2);
}

export function isGreaterOrEqualDate(date1:Date, date2:Date):boolean {
	return isGreaterDate(date1, date2) || isSameDate(date1, date2);
}

export function dateIsWithinDaysAgo(date1:Date, days:number):boolean {
	let now = new Date();
	now.setDate(now.getDate() - days);
	return isGreaterOrEqualDate(date1, now);
}

export function dateIsWithinMonthsAgo(date1:Date, months:number):boolean{
	let now = new Date();
	now.setMonth(now.getMonth() - months);
	return isGreaterOrEqualDate(date1, now);
}

export function dateIsWithinYearsAgo(date1:Date, year:number):boolean{
	let now = new Date();
	now.setFullYear(now.getFullYear() - year);
	return isGreaterOrEqualDate(date1, now);
}

export function dateIsOverDaysAgo(date1:Date, days:number):boolean {
	let now = new Date();
	now.setDate(now.getDate() - days);
	return isSmallerDate(date1, now);
}

export function dateIsOverMonthsAgo(date1:Date, months:number):boolean{
	let now = new Date();
	now.setMonth(now.getMonth() - months);
	return isSmallerDate(date1, now);
}

export function dateIsOverYearsAgo(date1:Date, year:number):boolean{
	let now = new Date();
	now.setFullYear(now.getFullYear() - year);
	return isSmallerDate(date1, now);
}


export function dateIsWithinDays(date1:Date, days:number):boolean{
	let now = new Date();
	now.setDate(now.getDate() + days);
	return isSamllerOrEqualDate(date1, now);
}


export function dateIsWithinMonths(date1:Date, months:number):boolean{
	let now = new Date();
	now.setMonth(now.getMonth() + months);
	return isSamllerOrEqualDate(date1, now);
}

export function dateIsWithinYears(date1:Date, year:number):boolean{
	let now = new Date();
	now.setFullYear(now.getFullYear() + year);
	return isSamllerOrEqualDate(date1, now);
}



export function dateIsOverDays(date1:Date, days:number):boolean{
	let now = new Date();
	now.setDate(now.getDate() + days);
	return isGreaterDate(date1, now);
}


export function dateIsOverMonths(date1:Date, months:number):boolean{
	let now = new Date();
	now.setMonth(now.getMonth() + months);
	return isGreaterDate(date1, now);
}

export function dateIsOverYears(date1:Date, year:number):boolean{
	let now = new Date();
	now.setFullYear(now.getFullYear() + year);
	return isGreaterDate(date1, now);
}

const locales:{[key:string]:Locale} = {
	"nl-be": Nl,
	"fr": Fr
};

export function formatDistanceToNow(date:Date):string{
	let locale = locales[i18n.locale];
	return formatDistanceToNowFromDateFns(date, {locale, addSuffix: true});
}


export function newNullOrDate(data:any):Date | null {
	if (!data) return null;
	return new Date(data);
}

export function dateToNullOrJson(date:Date | null):null | string {
	if (!date) return null;
	return date.toString();
}