
import Vue from "vue";
import { UserConfigService } from "@/services/user-config-service";
import { BrowseStateBase } from "@/utils/browse/browse-state-base";
import { ReportService } from "@/services/report-service";
import { IReportEngineSettingsWrapper } from "../../utils/report-engine-settings/report-engine-settings-wrapper";
import { BrowseExtraButtonLocation } from "@/utils/browse/browse-extra-button";
import { ISelectItem } from "winfakt-vue-components";
export default Vue.extend({
	data(){
		return{
			reportsDropDownOpen: false
		};
	},
	props: {
		state: {
			type: Object as ()=>BrowseStateBase,
			required: true,
		}
	},
	computed: {
		isEnabled():boolean{
			if (this.state.dataSource.canMakeReports != undefined) {
				if (!this.state.dataSource.canMakeReports) {
					return false;
				}
			}
			return UserConfigService.hasCurrentUserPermission((p)=>p.CanMakeReports);
		},
		reportExtraButtons():ISelectItem<Function>[]{
			return this.state.extraButtons.filter(b=>b.Location == BrowseExtraButtonLocation.REPORTS).map(b=>b.toSelectItem(this.state));
		}
	},
	methods: {
		openCustomReportModal() {
			this.reportsDropDownOpen = false;
			this.state.customReportsModalOpen = true;
		},
		printReport(settings:IReportEngineSettingsWrapper){
			this.reportsDropDownOpen = false;
			ReportService.openCreateReportModal(settings, settings.Settings.DefaultType);
		},
	},
	i18nEx: {
		componentPrefix: "components.browse"
	}
});
