import { v4 } from "uuid";
import { IModel } from "../models/model-interface";

export class SummaryCol {
	uid:string = v4();

	PreText:string = "";
	PostText:string = "";
	Col:string = "";

	public constructor(data?:any) {
		if (!data) return;
		this.PreText = data.PreText;
		this.PostText = data.PostText;
		this.Col = data.Col;
	}

	public getJSON(){
		return {
			PreText: this.PreText,
			PostText: this.PostText,
			Col: this.Col
		};
	}

	public getText(model:IModel){
		let col = "value";
		if (this.Col == ""){
			col = "";
		}else{
			let field = model.getField(this.Col);
			if (field){
				col = field.getPropertyTranslation();
			}
		}
		return `${this.PreText} ${col} ${this.PostText}`;
	}

}