import { i18n } from "@/setup/i18n-setup";
import { getSaleMapPropSelectGroup } from "../prop-select/sale-map-prop-group";
import { Field } from "./field";

export class SaleLayoutPaymentColSettings extends Field{
	Width:number = 10.0;
	Position:number = 0.0;
	HeaderField:Field | null = null;

	public constructor(data?:any){
		super(data);
		if (!data) return;
		this.Width = data.Width;
		this.Position = data.Position;
		if (data.HeaderField){
			this.HeaderField = new Field(data.HeaderField);
		}
	}

	public getJSON():any {
		let d:any = super.getJSON();
		d.Width = this.Width;
		d.Position = this.Position;
		if (this.HeaderField) {
			d.HeaderField = this.HeaderField.getJSON();
		}
		return d;
	}

	public getText(lang:string):string {
		let text = this.Text.getTranslation(lang);
		if (!this.Prop) {
			return text;
		}
		let propName = i18n.t(`models.map.Bank.${this.Prop}`).toString();
		return text + propName;
	}

	public getHeadingText(lang:string):string {
		if (!this.HeaderField) return "";
		let text = this.HeaderField.Text.getTranslation(lang);
		if (!this.HeaderField.Prop) {
			return text;
		}
		let group = getSaleMapPropSelectGroup();
		let val = group.getPropSelectFromValue(this.HeaderField.Prop);
		if (!val){
			return text;
		}
		return text + val.text;
	}

	public getWidth():string{
		return `${this.Width}mm`;
	}

	public getNumericPosition(cols:SaleLayoutPaymentColSettings[]):number{
		let naturalPosition = 0;
		for (let col of cols){
			if (col == this){
				break;
			}
			naturalPosition += col.Width + col.Style.MarginLeft + col.Style.MarginRight;
		}
		return (this.Position - naturalPosition);
	}

	public getNumericHeaderPosition(cols:SaleLayoutPaymentColSettings[]):number{
		let naturalPosition = 0;
		for (let col of cols){
			if (col == this){
				break;
			}
			if (!col.HeaderField) {
				continue;
			}
			naturalPosition += col.Width + col.HeaderField.Style.MarginLeft + col.HeaderField.Style.MarginRight;
		}
		return (this.Position - naturalPosition);
	}

	public getPosition(cols:SaleLayoutPaymentColSettings[]):string{
		return `${this.getNumericPosition(cols)}mm`;
	}


	public getHeaderPosition(cols:SaleLayoutPaymentColSettings[]):string{
		return `${this.getNumericHeaderPosition(cols)}mm`;
	}
}