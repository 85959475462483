import { render, staticRenderFns } from "./BrowseReportsDropdown.vue?vue&type=template&id=24db2e78&scoped=true"
import script from "./BrowseReportsDropdown.vue?vue&type=script&lang=ts"
export * from "./BrowseReportsDropdown.vue?vue&type=script&lang=ts"
import style0 from "./BrowseReportsDropdown.vue?vue&type=style&index=0&id=24db2e78&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24db2e78",
  null
  
)

export default component.exports